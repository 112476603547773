import * as Yup from "yup";

export const emailValidator =  Yup
    .string()
    .email('Почта должна быть в формате example@mail.com')
    .min(3, 'Длина почты должна быть в диапазоне от 3 до 254 символов')
    .max(254, 'Длина почты должна быть в диапазоне от 3 до 254 символов')
    .required('Укажите электронную почту');

export const passwordValidator =  Yup
    .string()
    .min(2, 'Длина пароля должна превышать 2 символа')
    .required('Укажите пароль');

export const fullNameValidator =  Yup
    .string()
    .max(255, 'Имя и фамилия не должны превышать 255 символов')
    .required('Укажите имя и фамилию');

export const usernameValidator =  Yup
    .string()
    .min(2, 'Длина логина должна быть в диапазоне от 2 до 30 символов')
    .max(30, 'Длина логина должна быть в диапазоне от 2 до 30 символов')
    .required('Укажите логин');
