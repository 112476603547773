import { logError } from '@edx/frontend-platform/logging';
import getCourseList from './api';
import {
  fetchEnrollmentsFailure,
  fetchEnrollmentsFirstRequest,
  fetchEnrollmentsRequest,
  fetchEnrollmentsSuccess,
} from './slice';

export default function fetchMoreEnrollments(pageNumber, pageSize) {
  return async (dispatch) => {
    if (pageNumber === 1) {
      dispatch(fetchEnrollmentsFirstRequest());
    } else {
      dispatch(fetchEnrollmentsRequest());
    }
    getCourseList(pageNumber, pageSize).then(
      courseListResult => {
        const enrollments = courseListResult.data.result;
        const coursesTotal = courseListResult.data.total_courses;
        dispatch(fetchEnrollmentsSuccess({ enrollments, coursesTotal, lastLoadedPage: pageNumber }));
      },
    )
      .catch((e) => {
        logError(e);
        dispatch(fetchEnrollmentsFailure());
      });
  };
}
