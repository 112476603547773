import { getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';
import { getConfig } from '@edx/frontend-platform';

export const postLoginSession = (email, password) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  return getAuthenticatedHttpClient().post(`${getConfig().LMS_BASE_URL}/api/user/v1/account/login_session/`, formData);
};

export const registerUser = ({
  email,
  name,
  username,
  password,
}) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('name', name);
  formData.append('username', username);
  formData.append('password', password);
  formData.append('terms_of_service', 'true');
  return getAuthenticatedHttpClient().post(`${getConfig().LMS_BASE_URL}/api/user/v2/account/registration/`, formData);
};

export const resetPassword = (email) => {
  const formData = new FormData();
  formData.append('email', email);
  return getAuthenticatedHttpClient()
    .post(`${getConfig().LMS_BASE_URL}/account/password`, formData);
};
