import React, { useEffect, useRef } from 'react';
import { ToraBlock } from '@skillfactory/tora-frontend';

const ToraPage = () => {
  const ref = useRef(null);

  useEffect(() => {
    const resizePostMessage = () => {
      if (!ref.current) { return; }

      if (window !== window.parent) {
        // TODO resize not working
        window.parent.postMessage({
          type: 'plugin.resize',
          payload: {
            width: ref.current.offsetWidth,
            height: ref.current.offsetHeight,
          },
        }, document.referrer);
      }
    };

    resizePostMessage();
    const observer = new MutationObserver(resizePostMessage);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });
    window.addEventListener('load', resizePostMessage);
    window.addEventListener('resize', resizePostMessage);

    return () => {
      observer.disconnect();
      window.removeEventListener('load', resizePostMessage);
      window.removeEventListener('resize', resizePostMessage);
    };
  }, []);

  return (
    <ToraBlock ref={ref} />
  );
};

export default ToraPage;
