import React, {useEffect, useMemo, useState} from 'react';
import {Button, Text} from "@skillfactory/frontend-theme";
import "./_exam-timer.scss";
import classNames from "classnames";

const TimerDigit = ({
    digit,
    className,
                    }) => {
    return <div className={classNames("sf-exam-timer-digit", className)}>
        {digit}
    </div>
}

const ExamTimer = ({
    seconds,
    handleSubmitExam,
    className,
                   }) => {
    const [countDown, setCountDown] = useState(seconds);
    const minutes = useMemo(() => Math.floor(countDown / 60), [countDown]);
    const hours = useMemo(() => Math.floor(countDown / 3600), [countDown]);

    useEffect(() => {
        setCountDown(seconds);
        const interval = setInterval(() => {
            setCountDown(prevState => prevState === 0? prevState: prevState - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    const getFirstDigitInDigitGroup = (unit) => {
        return Math.floor(unit % 60 / 10) % 10;
    }

    const getSecondDigitInDigitGroup = (unit) => {
        return unit % 10;
    }

    return (
        <div className={classNames("sf-exam-timer", className)}>
            <Text level={3} className="sf-exam-timer__title">
                До конца экзамена
            </Text>
            <div className="sf-exam-timer__digits">
                <div className="sf-exam-timer__digit-group">
                    <TimerDigit className="sf-exam-timer__digit" digit={getFirstDigitInDigitGroup(hours)}/>
                    <TimerDigit className="sf-exam-timer__digit" digit={getSecondDigitInDigitGroup(hours)}/>
                </div>
                <span className="sf-exam-timer__separator">
                    :
                </span>
                <div className="sf-exam-timer__digit-group">
                    <TimerDigit className="sf-exam-timer__digit" digit={getFirstDigitInDigitGroup(minutes)}/>
                    <TimerDigit className="sf-exam-timer__digit" digit={getSecondDigitInDigitGroup(minutes)}/>
                </div>
                <span className="sf-exam-timer__separator">
                    :
                </span>
                <div className="sf-exam-timer__digit-group">
                    <TimerDigit className="sf-exam-timer__digit" digit={getFirstDigitInDigitGroup(countDown)}/>
                    <TimerDigit className="sf-exam-timer__digit" digit={getSecondDigitInDigitGroup(countDown)}/>
                </div>
            </div>
            <Button onClick={() => handleSubmitExam()} variant="black" size="medium">Завершить экзамен</Button>
        </div>
    );
};

export default ExamTimer;
