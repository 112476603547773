import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Text, Tooltip } from '@skillfactory/frontend-theme';

const SequenceTab = forwardRef(({
  onClick,
  isCompleted,
  isCurrentLesson,
  unitTitle,
  className,
}, ref) => (
  <Tooltip
    gap={isCurrentLesson ? 1.5 : 9}
    position="top"
    content={(
      <div className="sf-unit-tab-tooltip">
        <Text level={4} className="sf-unit-tab-tooltip__title">{unitTitle}</Text>
      </div>
    )}
  >
    <div
      className={classNames('sf-unit-tab', { 'sf-unit-tab--current': isCurrentLesson, 'sf-unit-tab--completed': isCompleted }, className)}
      onClick={onClick}
      ref={ref}
    >
      {isCurrentLesson && <Text className="sf-unit-tab__text" level={5} weight="regular">текущий урок</Text>}
    </div>
  </Tooltip>
));

export default SequenceTab;
