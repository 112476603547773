import { useNotification } from '@skillfactory/frontend-theme';
import { useState } from 'react';
import { postLoginSession } from '../data/api';

export const useLogin = () => {
  const notification = useNotification();
  const [isLoggingIn, setLoggingIn] = useState(false);
  return {
    isLoggingIn,
    login: (values) => {
      setLoggingIn(true);
      return postLoginSession(values.email, values.password)
        .catch((err) => {
          const response = err?.customAttributes?.httpErrorResponseData;
          if (!response || err.customAttributes?.httpErrorStatus >= 500) {
            notification.show(
              'failure',
              'Возникла непредвиденная ошибка. Пожалуйста, обратитесь в поддержку.',
            );
            return;
          }
          const { value } = JSON.parse(response);
          notification.show(
            'failure',
            value,
          );
          return Promise.reject(err);
        }).finally(() => setLoggingIn(false));
    },
  };
};
