import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  genericError: {
    id: 'masquerade-widget.userName.error.generic',
    defaultMessage: 'An error has occurred; please try again.',
    description: 'Message shown after a general error when attempting to masquerade',
  },
  placeholder: {
    id: 'masquerade-widget.userName.input.placeholder',
    defaultMessage: 'Username or email',
    description: 'Placeholder text to prompt for a user to masquerade as',
  },
  userNameLabel: {
    id: 'masquerade-widget.userName.input.label',
    defaultMessage: 'Masquerade as this user',
    description: 'Label for the masquerade user input',
  },
  learner: {
    id: 'instructorToolbar.learner',
    defaultMessage: 'Learner',
  },
  staff: {
    id: 'instructorToolbar.staff',
    defaultMessage: 'Staff',
  },
  specificStudent: {
    id: 'instructorToolbar.specificStudent',
    defaultMessage: 'Specific Student...',
  },
  hiddenBlocks: {
    id: 'instructorToolbar.hiddenBlocks',
    defaultMessage: 'Specific Student...',
  },
});

export default messages;
