import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Title, Text } from '@skillfactory/frontend-theme';
import SkillFactoryPage from '../../../components/page-layout/SkillFactoryPage';
import './_register-success-page.scss';

const RegisterSuccessPage = () => {
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email');
  if (!email) {
    return <Redirect to="/login" />;
  }

  return (
    <SkillFactoryPage
      className="register-success-page"
    >
      <Title className="register-success-page__title" level={3}>Завершите регистрацию</Title>
      <Text className="register-success-page__msg" level={2}>Чтобы получить полный доступ к платформе, перейдите по ссылке в письме, мы отправили его на Вашу электронную почту</Text>
      <Text className="sf-auth-page-layout__note" level={4}>Нужна помощь? <a className="sf-auth-page-layout__note-link" href="#">Свяжитесь с поддержкой</a></Text>
    </SkillFactoryPage>
  );
};

export default RegisterSuccessPage;
