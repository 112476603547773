import React from 'react';
import {
  Title, Text, Button, ClassmateAvatars, InfoWidget,
  Loader,
} from '@skillfactory/frontend-theme';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

const getProgressDescription = (courseProgress) => {
  if (courseProgress === 0) { return 'Пора начинать!'; }
  if (courseProgress === 100) { return 'Отличная работа!'; }
  return 'Так держать!';
};

export const getNumberOfLessonsString = (number) => {
  const lastTwoDigits = number % 100;
  const lastDigit = lastTwoDigits % 10;
  if (!(lastTwoDigits >= 11 && lastTwoDigits <= 14)) {
    if (lastDigit === 1) { return 'Урок'; }
    if (lastDigit >= 2 && lastDigit <= 4) { return 'Урока'; }
  }
  return 'Уроков';
};

const OutlineHeader = ({
  courseName,
  lastLesson,
  numberOfLessonsLeft,
  classMatesNumber,
  courseProgress,
  isLoading,
}) => {
  const isLastLessonPresent = lastLesson.name && lastLesson.href;
  return (
    <div className="outline-header">
      {isLoading ? (
        <div className="outline-header__loader-container">
          <Loader className="outline-header__loader" />
        </div>
      ) : (
        <>
          <div>
            <Text className="outline-header__course-name" level={2}>{courseName}</Text>
            {isLastLessonPresent ? (
              <>
                <Title className="outline-header__last-lesson" level={3}>Вы остановились на уроке
                  «{lastLesson.name}»
                </Title>
                {/* author ego:
                  Comment this button component, because of a Link.
                  As I understand links can be only relative to current location, but in this case we need to
                  send the user to another domain - we need absolute-url based redirection.
                  Therefore, decided to change <Link> to <a>
                */}
                {/* <Button className="outline-header__link" as={<Link to={lastLesson.href} />} shape="sloped"> */}
                {/*  Перейти к уроку */}
                {/* </Button> */}
                <Button className="outline-header__link" as={<a href={lastLesson.href} />} shape="sloped">
                  Перейти к уроку
                </Button>
              </>
            ) : (
              <Title className="outline-header__last-lesson" level={3}>
                Пора начинать!
              </Title>
            )}
          </div>
          <div className="outline-header__widgets">
            {classMatesNumber && classMatesNumber > 0 && (
            <InfoWidget
              style={{
                width: 201,
                padding: '24px 20px',
              }}
              description="Учеников проходят курс вместе с тобой"
              footer={<ClassmateAvatars count={classMatesNumber} />}
              title={classMatesNumber}
              variant="page"
            />
            )}
            <InfoWidget
              style={{
                width: 190,
                padding: '24px 20px',
              }}
              title={numberOfLessonsLeft}
              description={`${getNumberOfLessonsString(numberOfLessonsLeft)} осталось до закрытия модуля`}
              footer="Вперед!"
              variant="page"
            />
            <InfoWidget
              style={{
                width: 231,
                padding: '24px 20px',
                ...(courseProgress === 0 ? { height: 218 } : {}),
              }}
              className={classNames({
                'outline-header__wrapped-widget': courseProgress === 0,
                'outline-header__not-wrapped-widget': courseProgress === 100,
              })}
              title={`${(courseProgress * 100).toFixed(0) || 0}%`}
              titleSub="из 100%"
              description="Прогресс прохождения курса"
              footer={getProgressDescription(courseProgress)}
              variant="page-wider"
            />
          </div>
        </>
      )}

    </div>
  );
};

export default OutlineHeader;
