import React from 'react';
import classNames from 'classnames';

const DashboardSection = ({
  header,
  className,
  children,
}) => {
  const Icon = header?.Icon;
  return (
    <section className={classNames('dashboard-section', className)}>
      {header && (
      <div className="dashboard-section__header">
        <div>
          <div className="dashboard-section__title-and-icon">
            {Icon && <Icon className="dashboard-section__icon" />}
            <div>
              <h2 className="dashboard-section__title">
                {header.title}
              </h2>
              {header.subtitle && (
              <span className="dashboard-section__subtitle">
                {header.subtitle}
              </span>
              )}
            </div>
          </div>
        </div>
        {header.rightElement}
      </div>
      )}
      <div className="dashboard-section__content">
        {children}
      </div>
    </section>
  );
};

export default DashboardSection;
