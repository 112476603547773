import React from 'react';
import classNames from 'classnames';
import { Text } from '@skillfactory/frontend-theme';
import InstructionsPageLayout from '../instructions-page-layout';
import './cost-info.scss';

export const CostInfoPage = ({
  className,
}) => (
  <InstructionsPageLayout className={classNames('sf-cost-info-page', className)}>
    <>
      <Text weight="regular" level={1}>
        Стоимость программного обеспечения рассчитывается индивидуально и формируется по запросу.
      </Text>
      <Text weight="regular" level={1}>
        Чтобы рассчитать стоимость напишите на почту <a className="sf-cost-info-page__link" href="mailto:info@skillfactory.ru">info@skillfactory.ru</a>.
      </Text>
    </>
  </InstructionsPageLayout>
);

export default CostInfoPage;
