import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { headerVariants } from '@skillfactory/frontend-component-header';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader } from '@skillfactory/frontend-theme';
import { getConfig } from '@edx/frontend-platform';
import SkillFactoryPage from '../components/page-layout/SkillFactoryPage';
import CourseTabView from '../course-tab-view/CourseTabView';
import { LOADED } from './data/slice';
import { fetchOutlineTab } from './data/thunks';
import { setExtendedSession } from './data/api';
import { getRedirectUrl } from '../auth/utils/get-redirect-url';

const CourseHomePage = ({
  children,
  targetUserId,
  courseId,
}) => {
  const {
    courseStatus,
  } = useSelector(state => state.courseHome);

  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const pugIdParam = search.split('=')[1];
    if (!search) {
      setIsLoading(false);
      return;
    }
    setExtendedSession(courseId, pugIdParam)
      .finally(res => {
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response.status !== 400) {
          const isContented = window.location.origin.includes('contented');
          window.location.href = getRedirectUrl(
            isContented ? getConfig().STUDENT_PROFILE_CONTENTED_URL : getConfig().STUDENT_PROFILE_URL,
          );
          return;
        }
        window.location.href = window.location.origin + window.location.pathname;
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center' }}><Loader style={{ width: 'fit-content', margin: 'auto' }} /></div> : (
        <SkillFactoryPage
          className="sf-outline-page"
          headerVariant={headerVariants.DARK}
          // isLoading={courseStatus !== LOADED}
          // additionalHeaderLinks={headerTabLinks}
          isCourseSpecificTabsShownInHeader
        >
          <CourseTabView>
            {children}
          </CourseTabView>
        </SkillFactoryPage>
      )}
    </>
  );
};

export default CourseHomePage;
