/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';
export const DENIED = 'denied';

const slice = createSlice({
  name: 'course-home',
  initialState: {
    courseStatus: 'loading',
    outlineStatus: LOADING,
    courseId: null,
    toastBodyText: null,
    toastBodyLink: null,
    toastHeader: '',
    progressStatus: LOADED,
    errorCode: 0,
    errorMessage: 'Oops',
    visibleSections: null,
    visibleSectionsLoading: true,
    modules: {},
    tasks: {},
    gradingPolicyStats: null,
    gradingPolicyStatsLoading: true,
    weightedScore: null,
    weightedScoreLoading: true,
  },
  reducers: {
    fetchTabDenied: (state, { payload }) => {
      state.courseId = payload.courseId;
      if (payload.tab === 'progress') {
        state.progressStatus = DENIED;
        return;
      }
      state.courseStatus = DENIED;
    },
    fetchTabFailure: (state, { payload }) => {
      state.courseId = payload.courseId;
      if (payload.tab === 'progress') {
        state.progressStatus = FAILED;
        return;
      }
      state.courseStatus = FAILED;
    },
    fetchTabRequest: (state, { payload }) => {
      state.courseId = payload.courseId;
      if (payload.tab === 'progress') {
        state.progressStatus = LOADING;
        return;
      }
      state.courseStatus = LOADING;
    },
    fetchTabSuccess: (state, { payload }) => {
      state.courseId = payload.courseId;
      state.targetUserId = payload.targetUserId;
      if (payload.tab === 'progress') {
        state.progressStatus = LOADED;
        return;
      }
      state.courseStatus = LOADED;
    },
    setCallToActionToast: (state, { payload }) => {
      const {
        header,
        link,
        linkText,
      } = payload;
      state.toastBodyLink = link;
      state.toastBodyText = linkText;
      state.toastHeader = header;
    },
    setProgressStatus: (state, { payload }) => {
      state.progressStatus = payload;
    },
    fetchVisibleSectionsRequest: (state) => {
      state.visibleSectionsLoading = true;
    },
    fetchVisibleSectionsSuccess: (state, { payload }) => {
      state.visibleSections = payload.visibleSections;
      state.visibleSectionsLoading = false;
    },
    fetchVisibleSectionsFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },

    fetchModulesFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },
    fetchModulesRequest: (state, { payload }) => {
      if (payload.sectionId) {
        const newMap = { ...state.modules };
        const newInfo = {
          sectionId: payload.sectionId,
          isLoading: true,
          pagesCount: state.modules[`${payload.sectionId}`]?.pagesCount ?? -1,
          lastLoadedPage: state.modules[`${payload.sectionId}`]?.lastLoadedPage ?? -1,
          lastQueriedPage: (state.modules[`${payload.sectionId}`]?.lastQueriedPage ?? -1),
          visibleModulesWithProgress: state.modules[`${payload.sectionId}`]?.visibleModulesWithProgress ?? [],
        };
        newMap[`${payload.sectionId}`] = newInfo;
        state.modules = newMap;
      }
    },
    fetchModulesSuccess: (state, { payload }) => {
      const newMap = state.modules;
      const newInfo = {
        sectionId: payload.sectionId,
        isLoading: false,
        pagesCount: payload.pagesCount,
        lastLoadedPage: Math.max(payload.lastLoadedPage, (state.modules[`${(payload.sectionId)}`]?.lastLoadedPage ?? -1)),
        lastQueriedPage: state.modules[`${payload.sectionId}`]?.lastQueriedPage ?? -1,
        visibleModulesWithProgress: [...(state.modules[`${payload.sectionId}`]?.visibleModulesWithProgress ?? []),
        ...payload.visibleModulesWithProgress],
      };

      newMap[`${payload.sectionId}`] = newInfo;
      state.modules = newMap;
    },
    fetchQueryModulesPage: (state, { payload }) => {
      if (payload.sectionId) {
        const newMap = { ...state.modules };
        const newInfo = {
          sectionId: payload.sectionId,
          isLoading: state.modules[`${payload.sectionId}`]?.isLoading ?? false,
          pagesCount: state.modules[`${payload.sectionId}`]?.pagesCount ?? -1,
          lastLoadedPage: state.modules[`${payload.sectionId}`]?.lastLoadedPage ?? -1,
          lastQueriedPage: Math.max((state.modules[`${payload.sectionId}`]?.lastQueriedPage ?? -1),
            payload.lastQueriedPage),
          visibleModulesWithProgress: state.modules[`${payload.sectionId}`]?.visibleModulesWithProgress ?? [],
        };
        newMap[`${payload.sectionId}`] = newInfo;
        state.modules = newMap;
      }
    },

    fetchTasksFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },
    fetchTasksRequest: (state, { payload }) => {
      if (payload.subsectionId) {
        const newMap = { ...state.tasks };
        const newInfo = {
          subsectionId: payload.subsectionId,
          isLoading: true,
          nextLessonIndex: state.tasks[`${payload.subsectionId}`]?.nextLessonIndex ?? 0,
          lastLoadedModule: state.tasks[`${payload.subsectionId}`]?.lastLoadedModule ?? -1,
          lastQueriedModule: Math.max((state.tasks[`${payload.subsectionId}`]?.lastQueriedModule ?? -1),
            payload.queriedModule),
          blocksWithProgress: state.tasks[`${payload.subsectionId}`]?.blocksWithProgress ?? [],
        };
        newMap[`${payload.subsectionId}`] = newInfo;

        state.tasks = newMap;
      }
    },
    fetchTasksSuccess: (state, { payload }) => {
      const newMap = state.tasks;
      const newInfo = {
        subsectionId: payload.subsectionId,
        isLoading: false,
        nextLessonIndex: payload.nextLessonIndex,
        lastLoadedModule: Math.max(payload.lastLoadedModule, (state.tasks[`${(payload.subsectionId)}`]?.lastLoadedModule ?? -1)),
        lastQueriedModule: state.tasks[`${payload.subsectionId}`]?.lastQueriedModule ?? -1,
        blocksWithProgress: [...(state.tasks[`${payload.subsectionId}`]?.blocksWithProgress ?? []),
        ...payload.blocksWithProgress],
      };

      newMap[`${payload.subsectionId}`] = newInfo;

      state.tasks = newMap;
    },
    fetchQueryTasksPage: (state, { payload }) => {
      if (payload.subsectionId) {
        const newMap = { ...state.tasks };
        const newInfo = {
          subsectionId: payload.subsectionId,
          isLoading: state.tasks[`${payload.subsectionId}`]?.isLoading ?? false,
          nextLessonIndex: state.tasks[`${payload.subsectionId}`]?.nextLessonIndex ?? 0,
          lastLoadedModule: state.tasks[`${payload.subsectionId}`]?.nextModuleIndex ?? -1,
          lastQueriedModule: Math.max((state.tasks[`${payload.subsectionId}`]?.lastQueriedModule ?? 0),
            payload.lastQueriedModule),
          blocksWithProgress: state.tasks[`${payload.subsectionId}`]?.blocksWithProgress ?? [],
        };
        newMap[`${payload.subsectionId}`] = newInfo;
        state.tasks = newMap;
      }
    },

    fetchGradingPolicyStatsRequest: (state) => {
      state.gradingPolicyStatsLoading = true;
    },
    fetchGradingPolicyStatsSuccess: (state, { payload }) => {
      state.gradingPolicyStats = payload.gradingPolicyStats;
      state.gradingPolicyStatsLoading = false;
    },
    fetchGradingPolicyStatsFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },

    fetchWeightedScoreRequest: (state) => {
      state.weightedScoreLoading = true;
    },
    fetchWeightedScoreSuccess: (state, { payload }) => {
      state.weightedScore = payload.weightedScore;
      state.weightedScoreLoading = false;
    },
    fetchWeightedScoresFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },
    fetchProgressAssignSuccess: (state, { payload }) => {
      if (payload.subsectionId && payload.blockId) {
        const newMap = { ...state.tasks };
        const subsection = newMap[`${payload.subsectionId}`];
        const task = subsection.blocksWithProgress.find(block => block.id === payload.blockId);
        const newTask = {
          ...task,
          grade: payload.grade,
        }
        subsection.blocksWithProgress = subsection.blocksWithProgress.map(block => block.id === payload.blockId ? newTask : block);
        newMap[`${payload.subsectionId}`] = subsection;
        state.tasks = newMap;
      }
    },
    fetchProgressAssignFailure: (state, { payload }) => {
      state.errorCode = payload.httpErrorStatus;
      state.errorMessage = payload.httpErrorResponseData;
      state.progressStatus = FAILED;
    },
  },
});

export const {
  fetchTabDenied,
  fetchTabFailure,
  fetchTabRequest,
  fetchTabSuccess,
  setCallToActionToast,
  setProgressStatus,
  fetchVisibleSectionsSuccess,
  fetchVisibleSectionsFailure,
  fetchGradingPolicyStatsSuccess,
  fetchGradingPolicyStatsFailure,
  fetchWeightedScoreSuccess,
  fetchWeightedScoresFailure,
  fetchModulesFailure,
  fetchModulesSuccess,
  fetchQueryModulesPage,
  fetchModulesRequest,
  fetchTasksFailure,
  fetchTasksSuccess,
  fetchQueryTasksPage,
  fetchTasksRequest,
  fetchWeightedScoreRequest,
  fetchVisibleSectionsRequest,
  fetchGradingPolicyStatsRequest,
  fetchProgressAssignSuccess,
  fetchProgressAssignFailure,
} = slice.actions;

export const {
  reducer,
} = slice;
