import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { layoutGenerator } from 'react-break';
import { useDispatch, useSelector } from 'react-redux';

import {
  BarChart, Button, ImprovementsNeeded, InputNumber, Loader, ProgressAssignmentModal, Modal, ModuleTable, Text, Warning, WeightedGradesBar,
} from '@skillfactory/frontend-theme';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import CertificateStatus from './certificate-status/CertificateStatus';
import CourseCompletion from './course-completion/CourseCompletion';
import CourseGrade from './grades/course-grade/CourseGrade';
import DetailedGrades from './grades/detailed-grades/DetailedGrades';
import GradeSummary from './grades/grade-summary/GradeSummary';
import ProgressHeader from './ProgressHeader';
import RelatedLinks from './related-links/RelatedLinks';
import messages from './grades/messages';

import { useModel } from '../../generic/model-store';
import { number } from 'prop-types';
import { fetchMoreModules, fetchMoreTasks, fetchProgressAssign } from '../data/thunks';
import { fetchQueryModulesPage, fetchQueryTasksPage } from '../data/slice';
import { string } from 'yup';
import { useUserInfo } from '../../utils/use-user-info';
import classNames from 'classnames';

const tasksPageSize = 10;
const tasksVisualPageSize = 10;

function ProgressTab({ intl, modulesPageSize }) {
  const {
    courseId, visibleSections, visibleSectionsLoading, gradingPolicyStats, gradingPolicyStatsLoading, weightedScore, weightedScoreLoading, modules, tasks,
  } = useSelector(state => state.courseHome);
  const dispatch = useDispatch();
  useEffect(() => {
    Array.prototype.forEach.call(Object.entries(tasks), task => {
      if ((task[1].lastQueriedModule > task[1].lastLoadedModule) &&
        !task[1].isLoading) {
        dispatch(fetchMoreTasks(courseId, task[1].subsectionId, task[1].lastQueriedModule, tasksPageSize));
      }
    });
  }, [tasks])

  useEffect(() => {
    Array.prototype.forEach.call(Object.entries(modules), module => {
      if ((module[1].lastQueriedPage > module[1].lastLoadedPage) &&
        !module[1].isLoading) {
        dispatch(fetchMoreModules(courseId, module[1].sectionId, module[1].lastLoadedPage + 1, modulesPageSize));
      }
    });
  }, [modules])

  const passingGrade = useMemo(() => {
    if (weightedScore) {
      let minGrade = weightedScore.criteriaWeights[0].value;
      Array.prototype.forEach.call(weightedScore.criteriaWeights, score => {
        if (score.value < minGrade) minGrade = score.value;
      })
      return minGrade;
    }
    return 0;
  }, [weightedScore]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentModalBlock, setCurrentModalBlock] = useState('');
  const [currentModalBlockName, setCurrentModalBlockName] = useState('');
  const [currentModalSubsection, setCurrentModalSubsection] = useState('');
  const [currentModalSubsectionName, setCurrentModalSubsectionName] = useState('');
  const [currentSectionName, setCurrentSectionName] = useState('');
  const [currentModalGrade, setCurrentModalGrade] = useState(0);
  const [currentModalMaxGrade, setCurrentModalMaxGrade] = useState(0);
  
  const isModalValueValid = useMemo(() => currentModalGrade >= 0 && currentModalGrade <= currentModalMaxGrade, 
  [currentModalGrade, currentModalMaxGrade]);
  const { isAdmin, isSuperUser } = useUserInfo();

  const onSendClick = (value) => {
    if (isAdmin || isSuperUser) {
      dispatch(fetchProgressAssign(courseId, currentModalSubsection, currentModalBlock, value, false));
    }
    setIsModalOpen(false);
  }

  return (
    <>
      <div className="row w-100 m-0 sf-progress-tab" style={{ marginTop: '-8px !important' }}>

        {gradingPolicyStatsLoading ? (
          <div className="sf-progress-tab__loader-container sf-progress-tab__loader-container--bar-chart">
            <Loader />
          </div>
        ) : (
          <BarChart
            policySettings={gradingPolicyStats.gradingPolicySettings}
            criterionProgress={gradingPolicyStats.criterionProgress}
            criterionSummary={gradingPolicyStats.criterionSummary}
            homeworkCriteriaName="Homework"
            examCriteriaName="Финальный экзамен"
            projectCriteriaName="Key Project"
            totalScore={gradingPolicyStats.totalScore}
            className="sf-progress-tab__bar-chart"
          />
        )}
        {/* Main body */}
        <div className="col-12 col-md-8 p-0">
          <div className="grades my-4 p-4">


            <div className="sf-progress-tab__weighted-grades-container">
              {weightedScoreLoading ? (
                <div className="sf-progress-tab__loader-container sf-progress-tab__loader-container--weighted-grades">
                  <Loader />
                </div>
              ) : (
                <>
                  <span className="sf-progress-tab__header-text">{intl.formatMessage(messages.grades)}</span>
                  <Text level={3} weight="regular" className="sf-progress-tab__sub-text">{intl.formatMessage(messages.courseGradeBody)}</Text>
                  <WeightedGradesBar
                    courseGrade={weightedScore.courseGrade}
                    criteriaWeights={weightedScore.criteriaWeights}
                    currentGradeLabel={intl.formatMessage(messages.currentGradeLabel)}
                  />

                </>
              )}
            </div>

            {/* <GradeSummary /> */}
            {/* <DetailedGrades /> */}
            <div className="sf-progress-tab__detailed-grades-container">
              <span className="sf-progress-tab__header-text">{intl.formatMessage(messages.detailedGrades)}</span>
              {visibleSectionsLoading ? (
                <div className="sf-progress-tab__loader-container">
                  <Loader />
                </div>
              ) : visibleSections?.visibleSections?.map(section => {
                const moduleShrt = modules[`${section.id}`];
                return (
                  <ModuleTable name={section.name} modules={moduleShrt?.visibleModulesWithProgress.map(module => {
                    const task = tasks[`${module.id}`];

                    return ({
                      name: module.name,
                      grade: module.grades,
                      summary: module.percent,
                      gradingPolicy: module.gradingPolicy,
                      blocksWithProgress: task?.blocksWithProgress ?? [],
                      loadMore: () => {
                        if (!task) {
                          dispatch(fetchQueryTasksPage({ subsectionId: module.id, lastQueriedModule: 0 }));
                        } else if (task.nextLessonIndex !== -1 && !task.isLoading) {
                          dispatch(fetchQueryTasksPage({ subsectionId: module.id, lastQueriedModule: task.nextLessonIndex }));
                        }
                      },
                      isEndReached: task && (task.nextLessonIndex === -1),
                      pageSize: Math.min(tasksVisualPageSize, !task || task.blocksWithProgress.length === 0 ? tasksVisualPageSize : task.blocksWithProgress.length),
                      hrefPrefix: `/learning/course/${courseId}/${module.id}`,
                      onTaskEdit: isAdmin || isSuperUser ? (blockId, blockName) => {
                        if (isAdmin || isSuperUser) {
                          setIsModalOpen(true);
                          setCurrentModalBlock(blockId);
                          setCurrentModalBlockName(blockName);
                          setCurrentModalSubsection(module.id);
                          setCurrentModalSubsectionName(module.name);
                          setCurrentSectionName(section.name);
                          const possible = task.blocksWithProgress.find(block => block.id === blockId).possible;
                          setCurrentModalMaxGrade(possible);
                        }
                      } : undefined,
                    });

                  }) ?? []}
                    loadMore={() => {
                      if (!moduleShrt) {
                        dispatch(fetchQueryModulesPage({ sectionId: section.id, lastQueriedPage: 0 }));
                      } else if (moduleShrt.pagesCount > moduleShrt.lastLoadedPage && !moduleShrt.isLoading) {
                        dispatch(fetchQueryModulesPage({ sectionId: section.id, lastQueriedPage: moduleShrt.lastLoadedPage + 1 }));
                      }
                    }}
                    isEndReached={moduleShrt && moduleShrt.pagesCount === moduleShrt.lastLoadedPage + 1}
                    isLoading={moduleShrt?.isLoading}
                    className="sf-progress-tab__visible-section"
                    noModulesMessage="В этом разделе нет оцениваемых модулей"
                  />
                );
              })}
              <ProgressAssignmentModal 
                sectionName={currentSectionName} 
                subsectionName={currentModalSubsectionName}
                blockName={currentModalBlockName}
                maxPoints={currentModalMaxGrade}
                isShown={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={(value) => {
                  if (isAdmin || isSuperUser) {
                    dispatch(fetchProgressAssign(courseId, currentModalSubsection, currentModalBlock, value, false));
                  }
                  setIsModalOpen(false);
                }}
                onSubmitMax={() => {
                  if (isAdmin || isSuperUser) {
                    dispatch(fetchProgressAssign(courseId, currentModalSubsection, currentModalBlock, 0, true));
                  }
                  setIsModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>

        {/* Side panel */}
        <div className="col-12 col-md-4 p-0 px-md-4">
        </div>
      </div>
    </>
  );
}

ProgressTab.propTypes = {
  intl: intlShape.isRequired,
  modulesPageSize: number.isRequired,
};

export default injectIntl(ProgressTab);
// export default ProgressTab;
