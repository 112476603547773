import React from 'react';
import {
  InputText, Button, Text, PasswordInput,
} from '@skillfactory/frontend-theme';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConfig } from '@edx/frontend-platform';
import { emailValidator, passwordValidator } from '../../utils/validators';
import AuthPageLayout from '../../auth-page-layout/AuthPageLayout';
import { useLogin } from '../../hooks/use-login';
import './_connect-account-page.scss';

const ConnectAccountPage = () => {
  const { login, isLoggingIn } = useLogin();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: emailValidator,
      password: passwordValidator,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => login(values)
      .then(() => {
        window.location.href = getConfig().OAUTH_COMPLETE_URL;
      })
      .catch(() => {}), // already handled;,
  });

  return (
    <AuthPageLayout
      title="Вы собираетесь присоединить аккаунт Университет 20.35 к вашему аккаунту Skillfactory!"
      subtitle="Для подтверждения действия войдите в учётную запись Skillfactory"
      bottomLeftElement={(
        <>
          <Text className="sf-auth-page-layout__footer-text" level={4}> Еще нет профиля?</Text>{' '}
          <Link to="/register" className="sf-auth-page-layout__link">Зарегистрируйтесь</Link>
        </>
          )}
      className="sf-connect-account-page"
      isLoading={isLoggingIn}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputText
          className="sf-auth-page-layout__input"
          label="Электронная почта"
          name="email"
          value={formik.values.email}
          errorMessage={formik.errors.email}
          onChange={value => formik.setFieldValue('email', value)}
        />
        <PasswordInput
          className="sf-auth-page-layout__input"
          label="Пароль"
          name="password"
          value={formik.values.password}
          errorMessage={formik.errors.password}
          onChange={value => formik.setFieldValue('password', value)}
        />
        <Link className="sf-auth-page-layout__note sf-auth-page-layout__note-link" to="password-reset">Забыли
          пароль?
        </Link>
        <Button
          shape="sloped"
          variant="lime"
          type="submit"
          className="sf-auth-page-layout__submit-btn"
          disabled={isLoggingIn}
        >
          Войти
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default ConnectAccountPage;
