import React from 'react';
import classNames from 'classnames';
import Subsection, { SubsectionStatus } from './subsection/Subsection';

const SubsectionList = ({
  subsections,
  className,
}) => (
  <ul className={classNames('sf-subsection-list', className)}>
    {subsections.map((subsection, index) => (
      <li className="sf-subsection-list__item" key={subsection.id}>
        <Subsection
          {...subsection}
          number={index + 1}
        />
      </li>
    ))}
  </ul>
);

export default SubsectionList;
