import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import './_telegram-binding-page.scss';
import { ReactComponent as SkillfactoryLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/skillfactory-logo.svg';
import { ReactComponent as ContentedLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/contented-logo-dark.svg';
import { AppContext } from '@edx/frontend-platform/react';
import { Redirect } from 'react-router';
import { TelegramBindingModal } from '@skillfactory/frontend-theme';
import { useQuery } from '../../../utils/use-query';
import confirmTelegramBinding from './data/api';

const computeState = (isSuccess, isError) => {
  if (isSuccess && !isError) { return 1; }
  if (!isSuccess && isError) { return 2; }
  return 0;
};

const prependWithHTTPS = (url) => {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return `https://${url}`;
  }
  return url;
};

const appendWithSlash = (url) => {
  if (!url.endsWith('/')) {
    return `${url}/`;
  }
  return url;
};

const TelegramBindingPage = () => {
  const query = useQuery();
  const redirectOnLoginUrl = encodeURIComponent(
    `${prependWithHTTPS(appendWithSlash(getConfig().BASE_URL))}learning/telegram-binding?activationKey=${query.get('activationKey')}`,
  );
  const { authenticatedUser } = useContext(AppContext);
  if (!authenticatedUser) {
    return <Redirect to={`/login?next=${redirectOnLoginUrl}`} />;
  }

  const [isSuccess, setIsSuccess] = useState(undefined);
  const [isError, setIsError] = useState(undefined);

  const onConfirm = () => {
    confirmTelegramBinding(query.get('activationKey'))
      .then(() => {
        setIsSuccess(true);
        setIsError(false);
      })
      .catch(() => {
        setIsSuccess(false);
        setIsError(true);
      });
  };

  const onLogout = () => {
    window.location.href = getConfig().LOGOUT_URL;
  };

  const onRedirect = () => {
    window.location.href = `${prependWithHTTPS(appendWithSlash(getConfig().BASE_URL))}learning/dashboard`;
  };

  return (
    <div className="sf-telegram-binding-page">
      <header className="sf-telegram-binding-page__header">
        <Link to={getConfig().DASHBOARD_PAGE_PATHNAME}>
          {window.location.hostname.includes(getConfig().CONTENTED_HOST)
            ? <ContentedLogo className="sf-telegram-binding-page__logo" />
            : <SkillfactoryLogo className="sf-telegram-binding-page__logo" />}
        </Link>
      </header>
      <TelegramBindingModal
        state={computeState(isSuccess, isError)}
        user={authenticatedUser}
        onConfirm={onConfirm}
        onRedirect={onRedirect}
        onLogout={onLogout}
      />
    </div>
  );
};

export default TelegramBindingPage;
