/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const LOADING_FIRST = 'loading_first';
export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';

const slice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardStatus: 'loaded',
    courseId: null,
    enrollments: [],
    coursesTotal: 0,
    lastLoadedPage: 0,
    lastQueriedPage: 0,
  },
  reducers: {
    fetchEnrollmentsFailure: (state) => {
      state.dashboardStatus = FAILED;
    },
    fetchEnrollmentsFirstRequest: (state) => {
      state.dashboardStatus = LOADING_FIRST;
    },
    fetchEnrollmentsRequest: (state) => {
      state.dashboardStatus = LOADING;
    },
    fetchEnrollmentsSuccess: (state, { payload }) => {
      state.enrollments = [...state.enrollments, ...payload.enrollments];
      state.coursesTotal = payload.coursesTotal;
      state.lastLoadedPage = Math.max(payload.lastLoadedPage, state.lastLoadedPage);
      state.dashboardStatus = LOADED;
    },
    fetchQueryPage: (state, { payload }) => {
      state.lastQueriedPage = Math.max(payload.lastQueriedPage, state.lastQueriedPage);
    },
  },
});

export const {
  fetchEnrollmentsFailure,
  fetchEnrollmentsRequest,
  fetchEnrollmentsFirstRequest,
  fetchEnrollmentsSuccess,
  fetchQueryPage,
} = slice.actions;

export const {
  reducer,
} = slice;
