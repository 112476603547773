import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import AuthPageLayout from '../../auth-page-layout/AuthPageLayout';
import './_reset-password-success-page.scss';

const ResetPasswordSuccessPage = () => {
  const location = useLocation();
  if (!location.state?.email) {
    return <Redirect to="/login" />;
  }

  return (
    <AuthPageLayout
      title="Проверьте почту"
      subtitle={`Чтобы создать новый пароль, перейдите по ссылке в письме, мы отправили его на ${location.state.email}`}
      bottomLeftElement={<Link to="/login" className="sf-auth-page-layout__link">Войти в профиль</Link>}
      className="reset-password-success-page"
    >
      <p className="reset-password-success-page__list-title">Если вы не получили письмо: </p>
      <ul className="reset-password-success-page__list">
        <li className="reset-password-success-page__list-item">Проверьте, правильно ли вы ввели адрес почты</li>
        <li className="reset-password-success-page__list-item">Проверьте папку «Спам»</li>
        <li className="reset-password-success-page__list-item">Если ничего не помогло,{' '}
          <a
            className="reset-password-success-page__link"
            href={window.location.href.includes(process.env.CONTENTED_HOST)
              ? process.env.CONTENTED_SUPPORT_URL
              : process.env.SKILLFACTORY_SUPPORT_URL}
          >
            обратитесь в поддержку
          </a>
        </li>
      </ul>
    </AuthPageLayout>
  );
};

export default ResetPasswordSuccessPage;
