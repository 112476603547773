import React, { useEffect } from 'react';
import { ReactComponent as SkillfactoryLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/skillfactory-logo.svg';
import { ReactComponent as ContentedLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/contented-logo-dark.svg';

import { SFFotter } from '@skillfactory/frontend-theme';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import './instructions-page-layout.scss';

export const InstructionsPageLayout = ({
  className,
  children,
}) => {
  useEffect(() => {
    window.focus();
  }, []);

  return (
    <div className={classNames('sf-instructions-page-layout', className)}>
      <header className="sf-instructions-page-layout__header">
        <Link to={getConfig().DASHBOARD_PAGE_PATHNAME}>
          {window.location.hostname.includes(getConfig().CONTENTED_HOST)
            ? <ContentedLogo className="sf-instructions-page-layout__logo" />
            : <SkillfactoryLogo className="sf-instructions-page-layout__logo" />}
        </Link>
      </header>
      <main className="sf-instructions-page-layout__main">

        <div className="sf-instructions-page-layout__content">
          {children}
        </div>
      </main>
      <SFFotter functionalUrl={`https://${getConfig().BASE_URL}/learning/functional-chars/`} usersGuidelineUrl={`https://${getConfig().BASE_URL}/learning/users-guideline/`} costInfoUrl={`https://${getConfig().BASE_URL}/learning/cost-info/`} scheme={window.location.href.includes(getConfig().CONTENTED_HOST) ? 'contented' : 'skillfactory'} />
    </div>
  );
};

export default InstructionsPageLayout;
