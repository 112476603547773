import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import {
  Text,
  Title,
} from '@skillfactory/frontend-theme';

import React, {
  useContext,
} from 'react';
import { AppContext } from '@edx/frontend-platform/react';
import { Redirect } from 'react-router-dom';
//import ForbiddenImage from '../assets/images/menhera-forbidden.png';
import SkillFactoryPage from '../components/page-layout/SkillFactoryPage';
import { useQuery } from '../utils/use-query';

// eslint-disable-next-line no-unused-vars
function Forbidden({ intl }) {
  const { authenticatedUser } = useContext(AppContext);
  const params = useQuery();
  const encodedAttemptedUri = params.get('attempted');
  if (!authenticatedUser) {
    return encodedAttemptedUri ? <Redirect to={`/login?next=${encodeURIComponent(encodedAttemptedUri)}`} /> : <Redirect to="/login" />;
  }

  return (
    <SkillFactoryPage className="sf-forbidden-page" isLoading={false}>
      <div className="sf-forbidden-page__container">
        {/* <img className="sf-forbidden-page__image" src={ForbiddenImage} alt="Pretty girl should be here..." /> */}
        <Title className="sf-forbidden-page__message-title" level={3}>Доступ к контенту, который вы пытаетесь открыть, запрещён.</Title>
        <Text className="sf-forbidden-page__message-text" level={2}>Обратитесь в службу поддержки для получения доступа.
        </Text>
      </div>
    </SkillFactoryPage>
  );
}

Forbidden.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Forbidden);
