import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import {
  CourseCard,
  RecentCourseBanner,
  Slide,
  Slider,
} from '@skillfactory/frontend-theme';

import React, {
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfig } from '@edx/frontend-platform';
import fetchMoreEnrollments from './data/thunks';
import DashboardSection from './dashboard-section';
import SkillFactoryPage from '../components/page-layout/SkillFactoryPage';
import { fetchQueryPage } from './data/slice';
import { getRedirectUrl } from '../auth/utils/get-redirect-url';

// eslint-disable-next-line no-unused-vars
function Dashboard({ intl }) {
  if (getConfig().STUDENT_PROFILE_URL && getConfig().STUDENT_PROFILE_CONTENTED_URL) {
    const isContented = window.location.href.includes('contented');
    window.location.href = getRedirectUrl(
      isContented ? getConfig().STUDENT_PROFILE_CONTENTED_URL : getConfig().STUDENT_PROFILE_URL,
    );
    return <></>;
  }

  const {
    enrollments, dashboardStatus, coursesTotal, lastQueriedPage, lastLoadedPage,
  } = useSelector(state => state.dashboard);
  const dispatch = useDispatch();
  const pageSize = 4;
  // slideShouldLoadPageLocalIndex is in range [ 0 : pageSize - 1 ]
  const slideShouldLoadPageLocalIndex = 3;

  useEffect(() => {
    dispatch(fetchQueryPage({ lastQueriedPage: 1 }));
  }, []);

  useEffect(() => {
    if (dashboardStatus === 'loaded' && lastQueriedPage > lastLoadedPage) {
      dispatch(fetchMoreEnrollments(lastLoadedPage + 1, pageSize));
    }
  }, [dashboardStatus, lastLoadedPage, lastQueriedPage]);

  const getCourseUrl = (enrollment) => {
    if (!enrollment) { return '/learning/dashboard'; }
    return `/learning/course/${enrollment.course_id}/home`;
    // ego 23.02.2023: this redirect we should not delete, let it be for now just a comment. Then we solve problem
    // on back, we will need to uncomment it.
    // return enrollment.course_details.course_id.includes('contented')
    //   ? `https://${getConfig().CONTENTED_HOST}${coursePathname}` : coursePathname;
  };

  // eslint-disable-next-line no-unused-vars
  const getResumeCourseUrl = (resumeCourseUrl, fallbackUrl) => {
    // if (theme === 'contented') {
    //   return fallbackUrl;
    // }
    const url = new URL(resumeCourseUrl);
    // ego 23.02.2023: this redirect we should not delete, let it be for now just a comment. Then we solve problem
    // on back, we will need to uncomment it.
    // if (url.pathname.includes('contented')) {
    //   url.hostname = getConfig().CONTENTED_HOST;
    // }
    return url.toString();
  };

  const renderEnrollment = (enrollment, index, props) => {
    const courseUrl = getCourseUrl(enrollment);
    return (
      <CourseCard
        isLoading={!enrollment}
        className="dashboard__course-card"
        backgroundColor={index % 2 === 0 ? '#ff6945' : '#7ae1f9'}
        textColor={index % 2 === 0 ? '#fbeec8' : '#0009df'}
        continueButtonLabel="Перейти к курсу"
        courseName={enrollment?.display_name}
        progressPercent={enrollment?.percent && (enrollment?.percent * 100).toFixed(0)}
        totalLessons={((enrollment?.complete_count || 0)
          + (enrollment?.incomplete_count || 0)
          + (enrollment?.locked_count || 0)) || null}
        viewedLessons={enrollment?.complete_count || 0}
        continueButtonProps={{
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          as: <a href={enrollment?.resume_course_url
            ? getResumeCourseUrl(enrollment.resume_course_url, courseUrl) : courseUrl}
          />,
        }}
          /* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
        as={<a href={courseUrl} />}
        {...props}
      />
    );
  };

  return (
    <SkillFactoryPage isLoading={dashboardStatus === 'loading_first'}>
      <div className="sf-container">
        <DashboardSection className="dashboard__recent-course-section">
          {enrollments.length > 0 && (
            <RecentCourseBanner
              className="dashboard__recent-course-banner"
              courseName={enrollments[0].display_name}
              lessonsLeft={enrollments[0].incomplete_count}
              getTitle={enrollments[0].percent !== 0 ? () => 'Продолжить обучение на курсе' : () => 'Пора начинать'}
                /* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
              courseButtonProps={{ as: <a href={getCourseUrl(enrollments[0])} /> }}
              continueButtonProps={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                as: <a href={enrollments[0].resume_course_url
                // eslint-disable-next-line max-len
                  ? getResumeCourseUrl(enrollments[0].resume_course_url, getCourseUrl(enrollments[0])) : getCourseUrl(enrollments[0])}
                />,
              }}
              continueButtonLabel={enrollments[0].percent !== 0 ? 'Продолжить' : 'Начать обучение'}
              progressPercent={(enrollments[0].percent * 100).toFixed(0) || 0}
              variant={enrollments[0].resume_course_block ? 'recent-course' : 'first-visit'}
              progressPercentDescription="Прогресс прохождения курса"
            />
          )}
        </DashboardSection>
        {enrollments.length > 0 && (
          <DashboardSection
            className="dashboard__my-courses-section"
            header={{
              title: 'Мои курсы',
              // rightElement: <LargeArrowButton
              //   description="Попроси помощи в Slack"
              //   icon={<SvgSlack viewBox="0 0 28 28" />}
              //   onClick={() => {}}
              //   text="Сложный этап?"
              // />,
            }}
          >
            {enrollments.length === 1 && enrollments
              .map((enrollment, index) => renderEnrollment(enrollment, index, {
                key: enrollment.course_id,
                shape: 'banner',
              }))}

            {enrollments.length === 2 && (
              <div className="dashboard__two-courses">
                {enrollments.map((enrollment, index) => renderEnrollment(enrollment, index, {
                  key: enrollment.course_id,
                }))}
              </div>
            )}

            {enrollments.length > 2 && (
              <Slider
                spaceBetween={32}
                mousewheel={{ forceToAxis: true }}
                className="dashboard__slider"
              >
                {Array.from(Array(coursesTotal)).map((_, index) => (
                  <Slide
                    shouldLoadMore={index % pageSize === slideShouldLoadPageLocalIndex}
                    loadMore={() => {
                      // eslint-disable-next-line no-unused-expressions
                      index + (pageSize - slideShouldLoadPageLocalIndex) < coursesTotal
                      // eslint-disable-next-line max-len
                      && dispatch(fetchQueryPage({ lastQueriedPage: (index + (pageSize - slideShouldLoadPageLocalIndex)) / pageSize + 1 }));
                    }}
                    key={index < enrollments.length ? enrollments[index].course_id : index}
                  >
                    {renderEnrollment(index < enrollments.length ? enrollments[index] : null, index)}
                  </Slide>
                ))}
              </Slider>
            )}
          </DashboardSection>
        )}
        {/* <section className="dashboard__project-factory-section"> */}
        {/*  <div className="dashboard__project-factory-section-text"> */}
        {/*    <h3 className="dashboard__project-factory-title">Фабрика проектов</h3> */}
        {/* eslint-disable-next-line max-len */}
        {/*    <p className="dashboard__project-factory-slogan">Прокачивай навыки на реальных проектах от наших партнеров</p> */}
        {/*    <Button */}
        {/*      className="dashboard__project-factory-button" */}
        {/*      onClick={() => {}} */}
        {/*      shape="sloped" */}
        {/*      variant="black" */}
        {/*      as={<a target="_blank" href={getConfig().PROJECTS_FACTORY} />} */}
        {/*    > */}
        {/*      Хочу в проект */}
        {/*    </Button> */}
        {/*  </div> */}
        {/*  <img className="dashboard__project-factory-section-image" src={ChessImage} alt="Фабрика проектов" /> */}
        {/* </section> */}
        {/* <DashboardSection */}
        {/*  className="dashboard__career-center-section" */}
        {/*  header={{ */}
        {/*    Icon: CareerCenterIcon, */}
        {/*    title: 'Центр карьеры', */}
        {/*    subtitle: 'Помощь экспертов в развитии твоей карьеры', */}
        {/*    rightElement: <Button */}
        {/*      onClick={() => {}} */}
        {/*      shape="sloped" */}
        {/*      variant="black" */}
        {/*    > */}
        {/*      Хочу такое */}
        {/*    </Button>, */}
        {/*  }} */}
        {/* > */}
        {/*  <CareerCenterSlider careers={[ */}
        {/*    { title: 'Ревью резюме', description: 'Дадим оценку вашему резюме и расскажем, что можно улучшить' }, */}
        {/* eslint-disable-next-line max-len */}
        {/*    { title: 'Карьерные консультации', description: 'Дадим оценку вашему резюме и расскажем, что можно улучшить' }, */}
        {/* eslint-disable-next-line max-len */}
        {/*    { title: 'Карьерные интенсивы', description: 'Дадим оценку вашему резюме и расскажем, что можно улучшить' }, */}
        {/* eslint-disable-next-line max-len */}
        {/*    { title: 'Полезные ресурсы', description: 'Дадим оценку вашему резюме и расскажем, что можно улучшить' }, */}
        {/* eslint-disable-next-line max-len */}
        {/*    { title: 'Карьерные мероприятия', description: 'Дадим оценку вашему резюме и расскажем, что можно улучшить' }, */}
        {/*  ]} */}
        {/*  /> */}
        {/* </DashboardSection> */}
      </div>
    </SkillFactoryPage>
  );
}

Dashboard.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Dashboard);
