import React, { useEffect } from 'react';
import { ReactComponent as SkillfactoryLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/skillfactory-logo.svg';
import { ReactComponent as ContentedLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/contented-logo-dark.svg';

import {
  useTheme, Title, Text, Loader, useScheme,
} from '@skillfactory/frontend-theme';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import { useDecodedRedirectQueryParam } from '../hooks/use-decoded-redirect-query-param';

export const AuthPageLayout = ({
  bgColor = 'green',
  bottomLeftElement,
  title,
  subtitle,
  className,
  children,
  isLoading,
}) => {
  const redirectUrl = useDecodedRedirectQueryParam();
  const theme = useTheme(state => state.theme);
  const scheme = useScheme(state => state.scheme);
  useEffect(() => {
    window.focus();
  }, []);

  const { SKILLFACTORY_SUPPORT_URL, CONTENTED_SUPPORT_URL } = getConfig();

  return (
    <div className={classNames('sf-auth-page-layout',
      {
        'sf-auth-page-layout--bg-blue': bgColor === 'blue',
        'sf-auth-page-layout--has-subtitle': subtitle,
      }, className)}
    >
      <header className="sf-auth-page-layout__header">
        <Link to={getConfig().DASHBOARD_PAGE_PATHNAME}>
          {window.location.hostname.includes(getConfig().CONTENTED_HOST)
            ? <ContentedLogo className="sf-auth-page-layout__logo" />
            : <SkillfactoryLogo className="sf-auth-page-layout__logo" />}
        </Link>

        {/* {redirectUrl && ( */}
        {/*  <RoundIconButton */}
        {/*    className="sf-auth-page-layout__cross-btn" */}
        {/*    icon={<CrossIcon />} */}
        {/*    onClick={() => { */}
        {/*      window.location.href = getRedirectUrl(redirectUrl); */}
        {/*    }} */}
        {/*    size="medium" */}
        {/*    variant="flat" */}
        {/*  /> */}
        {/* )} */}
      </header>
      <main className="sf-auth-page-layout__main">
        {isLoading
          ? <Loader />
          : (
            <div className="sf-auth-page-layout__content">
              <Title className="sf-auth-page-layout__title" level={6}>{title}</Title>
              {subtitle && <Text level={4} className="sf-auth-page-layout__subtitle">{subtitle}</Text>}
              {children}
            </div>
          )}
      </main>
      <footer className="sf-auth-page-layout__footer">
        <div>
          {bottomLeftElement}
        </div>
        <a className="sf-auth-page-layout__link" href={window.location.href.includes(getConfig().CONTENTED_HOST) ? CONTENTED_SUPPORT_URL : SKILLFACTORY_SUPPORT_URL} target="_blank">Обратиться в поддержку</a>
      </footer>
    </div>
  );
};

export default AuthPageLayout;
