import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@edx/paragon';
import { FormattedMessage } from '@edx/frontend-platform/i18n';
import classNames from 'classnames';
import useIndexOfLastVisibleChild from '../../../generic/tabs/useIndexOfLastVisibleChild';
import './_course-tabs.scss';
import { ReactComponent as ArrowIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/arrow.svg';
import { Link, useLocation } from 'react-router-dom';

export default function CourseTabs({ tabs, className, ...attrs }) {
  const { pathname } = useLocation();

  return (
    <nav
      {...attrs}
      className={classNames('nav flex-nowrap sf-course-tabs', className)}
    >
      {tabs.map(tab => {
        if (tab.slug === 'progress' || tab.slug === 'outline') {
          const tabUrl = `/${new URL(tab.url).pathname.split('/').slice(2).join('/')}`;
          return (
            <Link
              className={classNames('sf-course-tabs__tab', { 'sf-course-tabs__tab--active': tabUrl === pathname })}
              key={tab.url}
              to={{ pathname: tabUrl }}
            >
              {tab.title}
            </Link>
          );
        }

        return (
          <a className={classNames('sf-course-tabs__tab', { 'sf-course-tabs__tab--active': tab.slug === 'outline' })} key={tab.url} href={tab.url}>
            {tab.title}
          </a>
        );
      })}
    </nav>
  );
}

CourseTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CourseTabs.defaultProps = {
  children: null,
  className: undefined,
};
