/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';

const slice = createSlice({
    name: 'deadlines',
    initialState: {
        deadlines: [],
        deadlinesStatus: 'loading',
    },
    reducers: {
        fetchDeadlinesFailure: (state) => {
            state.deadlinesStatus = FAILED;
        },
        fetchDeadlinesRequest: (state) => {
            state.deadlinesStatus = LOADING;
        },
        fetchDeadlinesSuccess: (state, { payload }) => {
            state.deadlines = payload.deadlines;
            state.deadlinesStatus = LOADED;
        },
    },
});

export const {
    fetchDeadlinesFailure,
    fetchDeadlinesRequest,
    fetchDeadlinesSuccess,
} = slice.actions;

export const {
    reducer,
} = slice;
