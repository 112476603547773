import {useQuery} from "../../utils/use-query";

export const useDecodedRedirectQueryParam = () => {
    const params = useQuery();
    const encodedNext = params.get('next');
    const encodedRedirectUri = params.get('redirect_uri');
    return encodedRedirectUri
        ? decodeURI(encodedRedirectUri)
        : encodedNext
            ? decodeURI(encodedNext)
            : encodedNext;
};
