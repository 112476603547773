// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

// eslint-disable-next-line import/prefer-default-export
export const useUserInfo = () => {
    const cookieValue = Cookies.get('edx-jwt-cookie-header-payload');
    if (!cookieValue) {
        return {
            groups: [],
            isAdmin: null,
            isSuperUser: null,
        };
    }

    const decoded = jwt_decode(cookieValue);
    return {
        groups: decoded.groups ?? [],
        isAdmin: decoded.administrator ?? null,
        isSuperUser: decoded.superuser ?? null,
    };
};
