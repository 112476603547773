import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppContext } from '@edx/frontend-platform/react';

import { Loader, Notification } from '@skillfactory/frontend-theme';
import { fetchProgressTab } from '../data';
import { LOADED, fetchQueryModulesPage } from '../data/slice';
import ProgressTab from './ProgressTab';
import {
  fetchGradingPolicyStats, fetchVisibleSections, fetchWeightedScore,
} from '../data/thunks';

import './_progress-tab-loader.scss';

const modulesPageSize = 13;

function ProgressTabLoader() {
  const dispatch = useDispatch();
  const { authenticatedUser } = useContext(AppContext);

  const {
    courseId, progressStatus, errorCode, errorMessage,
  } = useSelector(state => state.courseHome);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchQueryModulesPage({ lastQueriedPage: 1 }));
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchGradingPolicyStats(courseId));
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchWeightedScore(courseId));
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchVisibleSections(courseId));
    }
  }, [courseId]);

  const message = useMemo(() => `Something went wrong. Error ${errorCode}: ${errorMessage}`, [errorCode, errorMessage]);

  // eslint-disable-next-line no-nested-ternary
  return courseId
    ? progressStatus === 'loaded' ? <ProgressTab modulesPageSize={modulesPageSize} /> : <Notification className="progress-tab-loader" variant="failure">{message}</Notification>
    : <Loader className="progress-tab-loader" />;
}

export default ProgressTabLoader;
