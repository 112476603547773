import React, {useContext, useEffect, useMemo, useState} from 'react';
import { AppContext } from '@edx/frontend-platform/react';
import PropTypes from 'prop-types';
import ExamTimer from "./ExamTimer";
import "./_exam-wrapper.scss";
import {fetchExamAttemptsData, finishExam} from "../data/api";

/**
 * Exam wrapper is responsible for triggering initial exam data fetching and rendering Exam.
 */
const ExamWrapper = ({ children, ...props }) => {
    const { authenticatedUser } = useContext(AppContext);
    const {
        sequence,
        courseId,
        isStaff,
        originalUserIsStaff,
        canAccessProctoredExams,
    } = props;
    // const { getExamAttemptsData, getAllowProctoringOptOut } = { getExamAttemptsData: () => {}, getAllowProctoringOptOut: () => {}};

    const [examAttemptsData, setExamAttemptsData] = useState(null);
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const getExamAttemptsData = async (courseId, sequenceId) => {
        try {
            const attemptData = await fetchExamAttemptsData(courseId, sequenceId);
            setExamAttemptsData(attemptData);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }
    const loadInitialData = async () => {
        await getExamAttemptsData(courseId, sequence.id);
        // await getAllowProctoringOptOut(sequence.allowProctoringOptOut);
    };

    const isGated = sequence && sequence.gatedContent !== undefined && sequence.gatedContent.gated;

    // useEffect(() => {
    //     if (!sequence.specialExamInfo) return;
    //     if (localStorage.getItem(sequence.id)) return;
    //     localStorage.setItem(sequence.id, 'started');
    //     window.location.reload();
    // }, [sequence]);
    // Using state instead of redux store because idk how to correctly handle spa navigation

    useEffect(() => {
        loadInitialData();
    }, [courseId, sequence])

    const activeAttempt = useMemo(() => examAttemptsData?.active_attempt, [examAttemptsData]);
    const activeAttemptSecondsLeft = useMemo(() => activeAttempt?.time_remaining_seconds, [activeAttempt]);

    // if the user is browsing public content (not logged in) they cannot be in an exam
    // if the user is staff they may view exam content without an exam attempt
    // any requests for exam state will 403 so just short circuit this component here
    if (!authenticatedUser || isStaff) {
        return children;
    }

    return (
        // <Exam
        //     isGated={isGated}
        //     isTimeLimited={sequence.isTimeLimited}
        //     originalUserIsStaff={originalUserIsStaff}
        //     canAccessProctoredExams={canAccessProctoredExams}
        // >
        //     {children}
        // </Exam>
        <>
            {activeAttempt?.course_id === courseId && activeAttemptSecondsLeft !== undefined && <ExamTimer className="sf-exam-wrapper-timer" seconds={Math.floor(activeAttemptSecondsLeft)} handleSubmitExam={() => finishExam(activeAttempt.attempt_id).then(() => window.location.reload())}/>}
            {children}
        </>
    );
};

ExamWrapper.propTypes = {
    sequence: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isTimeLimited: PropTypes.bool,
        allowProctoringOptOut: PropTypes.bool,
        gatedContent: PropTypes.shape({
            gated: PropTypes.bool,
        }),
    }).isRequired,
    courseId: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    isStaff: PropTypes.bool,
    originalUserIsStaff: PropTypes.bool,
    canAccessProctoredExams: PropTypes.bool,
};

ExamWrapper.defaultProps = {
    isStaff: false,
    originalUserIsStaff: false,
    canAccessProctoredExams: true,
};

export default ExamWrapper;
