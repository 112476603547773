/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'auth',
  initialState: {
    color: 'green',
  },
  reducers: {
    setColor: (state, { payload }) => {
      state.color = payload.color;
    },

  },
});

export const {
  setColor,
} = slice.actions;

export const {
  reducer,
} = slice;
