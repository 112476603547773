import { logError } from '@edx/frontend-platform/logging';
import { camelCaseObject, getConfig } from '@edx/frontend-platform';
import {
  executePostFromPostEvent,
  getCourseHomeCourseMetadata,
  getDatesTabData,
  getGradingPolicyStats,
  getOutlineTabData,
  getProgressTabData,
  getVisibleModules,
  getVisibleSections,
  getVisibleTasks,
  getWeightedScore,
  postCourseDeadlines,
  postCourseGoals,
  postDismissWelcomeMessage,
  postProgressAssign,
  postRequestCert,
} from './api';

import {
  addModel,
} from '../../generic/model-store';

import {
  fetchGradingPolicStatsRequest,
  fetchGradingPolicyStatsFailure,
  fetchGradingPolicyStatsRequest,
  fetchGradingPolicyStatsSuccess,
  fetchModulesFailure,
  fetchModulesRequest,
  fetchModulesSuccess,
  fetchProgressAssignSuccess,
  fetchTabDenied,
  fetchTabFailure,
  fetchTabRequest,
  fetchTabSuccess, fetchTasksFailure, fetchTasksRequest, fetchTasksSuccess, fetchVisibleSectionsFailure, fetchVisibleSectionsRequest, fetchVisibleSectionsSuccess, fetchWeightedScoreRequest, fetchWeightedScoresFailure, fetchWeightedScoreSuccess, LOADED,
  setCallToActionToast, setProgressStatus,
} from './slice';

const eventTypes = {
  POST_EVENT: 'post_event',
};

export function fetchTab(courseId, tab, getTabData, targetUserId) {
  return async (dispatch) => {
    dispatch(fetchTabRequest({ courseId, tab }));
    Promise.allSettled([
      getCourseHomeCourseMetadata(courseId),
      getTabData(courseId, targetUserId),
    ]).then(([courseHomeCourseMetadataResult, tabDataResult]) => {
      const fetchedCourseHomeCourseMetadata = courseHomeCourseMetadataResult.status === 'fulfilled';
      const fetchedTabData = tabDataResult.status === 'fulfilled';

      if (fetchedCourseHomeCourseMetadata) {
        dispatch(addModel({
          modelType: 'courseHomeMeta',
          model: {
            id: courseId,
            ...courseHomeCourseMetadataResult.value,
          },
        }));
      } else {
        logError(courseHomeCourseMetadataResult.reason);
        window.location.href = `${getConfig().LMS_BASE_URL}/courses/${courseId}/about`;
      }

      if (fetchedTabData) {
        dispatch(addModel({
          modelType: tab,
          model: {
            id: courseId,
            ...tabDataResult.value,
          },
        }));
      } else {
        logError(tabDataResult.reason);
      }

      // Disable the access-denied path for now - it caused a regression
      if (fetchedCourseHomeCourseMetadata && !courseHomeCourseMetadataResult.value.courseAccess.hasAccess) {
        dispatch(fetchTabDenied({ courseId, tab }));
      } else if (fetchedCourseHomeCourseMetadata && fetchedTabData) {
        dispatch(fetchTabSuccess({ courseId, targetUserId, tab }));
      } else {
        dispatch(fetchTabFailure({ courseId, tab }));
      }
    });
  };
}

// fixme mb DRY it somehow
export function fetchTabWithOutline(courseId, tab, getTabData, targetUserId) {
  return async (dispatch) => {
    dispatch(fetchTabRequest({ courseId }));
    Promise.allSettled([
      getCourseHomeCourseMetadata(courseId),
      getOutlineTabData(courseId),
      getTabData(courseId, targetUserId),
    ]).then(([courseHomeCourseMetadataResult, outlineDataResult, tabDataResult]) => {
      const fetchedCourseHomeCourseMetadata = courseHomeCourseMetadataResult.status === 'fulfilled';
      const fetchedTabData = tabDataResult.status === 'fulfilled';

      if (fetchedCourseHomeCourseMetadata) {
        dispatch(addModel({
          modelType: 'courseHomeMeta',
          model: {
            id: courseId,
            ...courseHomeCourseMetadataResult.value,
          },
        }));
      } else {
        logError(courseHomeCourseMetadataResult.reason);
        // window.location.href = `${getConfig().LMS_BASE_URL}/courses/${courseId}/about`;
      }

      if (outlineDataResult) {
        dispatch(addModel({
          modelType: 'outline',
          model: {
            id: courseId,
            ...outlineDataResult.value,
          },
        }));
      } else {
        logError(outlineDataResult.reason);
      }

      if (fetchedTabData) {
        dispatch(addModel({
          modelType: tab,
          model: {
            id: courseId,
            ...tabDataResult.value,
          },
        }));
      } else {
        logError(tabDataResult.reason);
      }

      // Disable the access-denied path for now - it caused a regression
      if (fetchedCourseHomeCourseMetadata && !courseHomeCourseMetadataResult.value.courseAccess.hasAccess) {
        dispatch(fetchTabDenied({ courseId }));
      } else if (fetchedCourseHomeCourseMetadata && fetchedTabData && outlineDataResult) {
        dispatch(fetchTabSuccess({ courseId, targetUserId }));
      } else {
        dispatch(fetchTabFailure({ courseId }));
      }
    });
  };
}

export function fetchDatesTab(courseId) {
  return fetchTab(courseId, 'dates', getDatesTabData);
}

export function fetchProgressTab(courseId, targetUserId) {
  return fetchTab(courseId, 'progress', getProgressTabData, parseInt(targetUserId, 10) || targetUserId);
}

export function fetchVisibleSections(courseId) {
  return async (dispatch) => {
    dispatch(fetchVisibleSectionsRequest());
    getVisibleSections(courseId).then(
      visibleSections => dispatch(fetchVisibleSectionsSuccess({ visibleSections })),
    ).catch((e) => {
      const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

      logError(e);
      dispatch(fetchVisibleSectionsFailure({ httpErrorStatus, httpErrorResponseData }));
    });
  };
}

export function fetchMoreModules(courseId, sectionId, pageNumber, pageSize) {
  return async (dispatch) => {
    dispatch(fetchModulesRequest({ sectionId }));
    getVisibleModules(courseId, sectionId, pageNumber, pageSize).then(
      visibleModulesResult => {
        const { visibleModulesWithProgress, pagesCount } = visibleModulesResult;
        dispatch(fetchModulesSuccess({
          sectionId, lastLoadedPage: pageNumber, pagesCount, visibleModulesWithProgress,
        }));
      },
    )
      .catch((e) => {
        const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

        logError(e);
        dispatch(fetchModulesFailure({ httpErrorStatus, httpErrorResponseData }));
      });
  };
}

export function fetchMoreTasks(courseId, subsectionId, module, pageSize) {
  return async (dispatch) => {
    dispatch(fetchTasksRequest({ subsectionId, queriedModule: module }));
    getVisibleTasks(courseId, subsectionId, module, pageSize).then(
      visibleTasksResult => {
        const { blocksWithProgress, nextLessonIdx } = visibleTasksResult;
        dispatch(fetchTasksSuccess({
          subsectionId, lastLoadedModule: module, nextLessonIndex: nextLessonIdx, blocksWithProgress,
        }));
      },
    )
      .catch((e) => {
        const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

        logError(e);
        dispatch(fetchTasksFailure({ httpErrorStatus, httpErrorResponseData }));
      });
  };
}

export function fetchGradingPolicyStats(courseId) {
  return async (dispatch) => {
    dispatch(fetchGradingPolicyStatsRequest());

    getGradingPolicyStats(courseId).then(
      gradingPolicyStats => {
        dispatch(fetchGradingPolicyStatsSuccess({ gradingPolicyStats }));
      },
    ).catch((e) => {
      const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

      logError(e);
      dispatch(fetchGradingPolicyStatsFailure({ httpErrorStatus, httpErrorResponseData }));
    });
  };
}

export function fetchWeightedScore(courseId) {
  return async (dispatch) => {
    dispatch(fetchWeightedScoreRequest());

    getWeightedScore(courseId).then(
      weightedScore => {
        dispatch(fetchWeightedScoreSuccess({ weightedScore }));
      },
    ).catch((e) => {
      const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

      logError(e);
      dispatch(fetchWeightedScoresFailure({ httpErrorStatus, httpErrorResponseData }));
    });
  };
}

export function fetchProgressAssign(courseId, subsectionId, blockId, grade, shouldBeMax) {
  return async (dispatch) => {
    postProgressAssign(courseId, blockId, grade, shouldBeMax).then(
      () => {
        dispatch(fetchProgressAssignSuccess({ subsectionId, blockId, grade }));
      },
    ).then(() => {
      // dispatch(fetchGradingPolicyStats(courseId));
      // dispatch(fetchWeightedScore(courseId));
      window.location.reload();
    }).catch((e) => {
      const { httpErrorStatus, httpErrorResponseData } = e && e.customAttributes;

      logError(e);
      dispatch(fetchWeightedScoresFailure({ httpErrorStatus, httpErrorResponseData }));
    });
  };
}

export function fetchOutlineAndProgress(courseId, targetUserId) {
  return fetchTabWithOutline(courseId, 'progress', getProgressTabData, parseInt(targetUserId, 10) || targetUserId);
}

export function fetchOutlineTab(courseId) {
  return fetchTab(courseId, 'outline', getOutlineTabData);
}

export function dismissWelcomeMessage(courseId) {
  return async () => postDismissWelcomeMessage(courseId);
}

export function requestCert(courseId) {
  return async () => postRequestCert(courseId);
}

export function resetDeadlines(courseId, model, getTabData) {
  return async (dispatch) => {
    postCourseDeadlines(courseId, model).then(response => {
      const { data } = response;
      const {
        header,
        link,
        link_text: linkText,
      } = data;
      dispatch(getTabData(courseId));
      dispatch(setCallToActionToast({ header, link, linkText }));
    });
  };
}

export async function saveCourseGoal(courseId, goalKey) {
  return postCourseGoals(courseId, goalKey);
}

export function processEvent(eventData, getTabData) {
  return async (dispatch) => {
    // Pulling this out early so the data doesn't get camelCased and is easier
    // to use when it's passed to the backend
    const { research_event_data: researchEventData } = eventData;
    const event = camelCaseObject(eventData);
    if (event.eventName === eventTypes.POST_EVENT) {
      executePostFromPostEvent(event.postData, researchEventData).then(response => {
        const { data } = response;
        const {
          header,
          link,
          link_text: linkText,
        } = data;
        dispatch(getTabData(event.postData.bodyParams.courseId));
        dispatch(setCallToActionToast({ header, link, linkText }));
      });
    }
  };
}
