import React from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import { Text, Title } from '@skillfactory/frontend-theme';
import CompletionDonutChart from './CompletionDonutChart';
import messages from './messages';

function CourseCompletion({ intl }) {
  return (
    <section className="course-completion">
      <div className="row w-100 m-0">
        <div className="col-12 col-sm-6 col-md-7 p-0">
          <Title className="course-completion__title" level={5}>
            {intl.formatMessage(messages.courseCompletion)}
          </Title>
          <Text level={3} weight="regular">
            {intl.formatMessage(messages.completionBody)}
          </Text>
        </div>
        <div className="col-12 col-sm-6 col-md-5 mt-sm-n3 p-0 text-center">
          <CompletionDonutChart />
        </div>
      </div>
    </section>
  );
}

CourseCompletion.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CourseCompletion);
