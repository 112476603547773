import { useEffect, useRef } from 'react';
import { getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';
import { getConfig } from '@edx/frontend-platform';

const removeWidget = (scriptEl) => {
  if (!scriptEl) {
    return;
  }
  if (scriptEl.nextElementSibling) {
    document.body.removeChild(scriptEl.nextElementSibling); // fixme quite dangerous
  }
  document.body.removeChild(scriptEl);
};

export const useFeedbackWidget = (courseId) => {
  const scriptRef = useRef(null);
  useEffect(() => {
    // Remove widget if it's not course page or widget already exists
    if (!courseId || scriptRef.current) {
      removeWidget(scriptRef.current);
      scriptRef.current = null;
      return;
    }

    const script = document.createElement('script');
    const url = `${getConfig().LMS_BASE_URL}/api/extended/feedback_widget/${courseId}`;
    getAuthenticatedHttpClient()
      .get(url)
      .then(({ data }) => {
        const widget = data.feedback_widget;
        if (!widget || typeof widget !== 'string') { return; }
        if (widget.includes('</script>')) {
          const template = document.createElement('template');
          template.innerHTML = widget.trim();
          const clonableScript = template.content.firstChild;
          if (!clonableScript?.attributes) { return; }
          [...clonableScript.attributes].forEach(attribute => script.setAttribute(attribute.nodeName, attribute.nodeValue));
        } else {
          // hde specific attributes
          script.src = widget;
          script.id = 'hde-chat-widget';
          script.dataset.lang = 'ru';
          script.dataset.host = 'skillfactory.helpdeskeddy.com';
          script.dataset.position = 'bottom-right';
        }

        document.body.appendChild(script);
        scriptRef.current = script;
      });
    return () => {
      removeWidget(scriptRef.current);
    };
  }, [courseId]);
};
