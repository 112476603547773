import React, { useContext, useMemo } from 'react';
import {
  InputText, Button, Text, PasswordInput,
} from '@skillfactory/frontend-theme';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConfig } from '@edx/frontend-platform';
import { AppContext } from '@edx/frontend-platform/react';
import { emailValidator, passwordValidator } from '../../utils/validators';
import { useDecodedRedirectQueryParam } from '../../hooks/use-decoded-redirect-query-param';
import AuthPageLayout from '../../auth-page-layout/AuthPageLayout';
import './_login-page.scss';
import { ReactComponent as SsoIcon } from '../../../assets/images/university2035.svg';
import { useLogin } from '../../hooks/use-login';
import { getRedirectUrl } from '../../utils/get-redirect-url';

const doSecuredRedirect = (redirectUrl, schema, firstDomain, secondDomain) => {
  if (redirectUrl
    && schema && (schema === 'https:' || schema === 'http:')
    && firstDomain && firstDomain === 'ru'
    && secondDomain && (
    secondDomain === process.env.ALLOWED_DOMAIN_SF || secondDomain === process.env.ALLOWED_DOMAIN_CD
  )) {
    window.location.href = getRedirectUrl(redirectUrl);
  } else {
    const isContented = window.location.href.includes('contented');
    window.location.href = getRedirectUrl(
      isContented ? getConfig().STUDENT_PROFILE_CONTENTED_URL : getConfig().STUDENT_PROFILE_URL,
    );
  }
};

const LoginPage = () => {
  const redirectUrl = useDecodedRedirectQueryParam();
  const history = useHistory();
  const { login, isLoggingIn } = useLogin();
  const decodedRedirectUrl = decodeURIComponent(getRedirectUrl(redirectUrl));
  const [schema,, domain] = decodedRedirectUrl.split('/');
  const [firstDomain, secondDomain] = domain?.split('.').reverse() ?? [];

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: emailValidator,
      password: passwordValidator,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => login(values)
      .then(() => doSecuredRedirect(redirectUrl, schema, firstDomain, secondDomain, history))
      // .catch(error => console.log(error))
      .catch(() => {}) // already handled;
    ,
  });

  const university2035Url = getConfig().SSO_LOGIN_URL;
  const university2035LoginUrl = useMemo(() => {
    if (!university2035Url) { return undefined; }
    const ssoUrl = new URL(university2035Url);
    ssoUrl.searchParams.set('auth-entry', encodeURIComponent('login'));
    if (redirectUrl) {
      ssoUrl.searchParams.set('next', encodeURIComponent(redirectUrl));
    } else {
      ssoUrl.searchParams.set('next', encodeURIComponent('/'));
    }
    return ssoUrl.toString();
  }, [redirectUrl, university2035Url]);

  const { authenticatedUser } = useContext(AppContext);
  if (authenticatedUser) {
    doSecuredRedirect(redirectUrl, schema, firstDomain, secondDomain, history);
    return null;
  }

  return (
    <AuthPageLayout
      title="Войдите в профиль"
      bottomLeftElement={(
        <>
          <Text className="sf-auth-page-layout__footer-text" level={4}> Еще нет профиля?</Text>{' '}
          <Link to="/register" className="sf-auth-page-layout__link">Зарегистрируйтесь</Link>
        </>
)}
      className="sf-login-page"
      isLoading={isLoggingIn}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputText
          className="sf-auth-page-layout__input"
          label="Электронная почта"
          name="email"
          value={formik.values.email}
          errorMessage={formik.errors.email}
          onChange={value => formik.setFieldValue('email', value)}
        />
        <PasswordInput
          className="sf-auth-page-layout__input"
          label="Пароль"
          name="password"
          value={formik.values.password}
          errorMessage={formik.errors.password}
          onChange={value => formik.setFieldValue('password', value)}
        />
        <Link className="sf-auth-page-layout__note sf-auth-page-layout__note-link" to="password-reset">Забыли
          пароль?
        </Link>
        <Button
          shape="sloped"
          variant="lime"
          type="submit"
          className="sf-auth-page-layout__submit-btn"
          disabled={isLoggingIn}
        >
          Войти
        </Button>
        {/* {error && <Text className="sf-auth-page-layout__error-msg" level={7}>{error}</Text>} */}
      </form>
      {university2035LoginUrl && (
      <div className="sf-login-page__oauth-providers">
        <Text level={5}>Войти с помощью</Text>
        <a className="sf-login-page__sso-link" href={university2035LoginUrl}>
          <SsoIcon className="sf-login-page__sso-link-icon" />Университет 20.35
        </a>
      </div>
      )}
    </AuthPageLayout>
  );
};

export default LoginPage;
