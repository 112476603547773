import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { HomeworkStatus, Tag } from '@skillfactory/frontend-theme';

export const SubsectionStatus = {
  GRADED: 'GRADED',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  BLOCKED: 'BLOCKED',
};

const getVariant = (status, dateUntil) => {
  if (status === SubsectionStatus.GRADED) { return 'reviewed'; }
  if (status === SubsectionStatus.UNDER_REVIEW) { return 'under-review'; }
  if (status === SubsectionStatus.BLOCKED) { return 'too-early'; }
  if (!dateUntil) { return 'hand-in'; }
  return Math.ceil((dateUntil.getTime() - Date.now()) / (1000 * 3600 * 60)) <= 2
    ? 'hand-in-red' : 'hand-in';
};

export const getNumberOfLessonsString = (number) => {
  const lastTwoDigits = number % 100;
  const lastDigit = lastTwoDigits % 10;
  if (!(lastTwoDigits >= 11 && lastTwoDigits <= 14)) {
    if (lastDigit === 1) { return `${number} урок`; }
    if (lastDigit >= 2 && lastDigit <= 4) { return `${number} урока`; }
  }
  return `${number} уроков`;
};

const Subsection = ({
  isIndicated,
  href,
  title,
  numberOfTasks,
  status,
  earnedGrade,
  possibleGrade,
  dueDate,
  number,
  isCurrent,
  className,
}) => {
  const homeworkStatusVariant = getVariant(status, dueDate);
  return (
    <Link className={classNames('sf-subsection', { 'sf-subsection--indicated': isIndicated }, className)} to={href}>
      <div className="sf-subsection__title-and-number-of-tasks">
        <h3 className="sf-subsection__title">
          <span className="sf-subsection__number">{number}&nbsp;&nbsp;</span>
          {title}
        </h3>
        <span className="sf-subsection__number-of-tasks">
          {getNumberOfLessonsString(numberOfTasks)}
        </span>
        {isCurrent && (
          <Tag
            className="sf-subsection__current-lesson-tag"
            color="lime"
          >
            Текущий модуль
          </Tag>
        )}
      </div>
      {}
      {((homeworkStatusVariant === 'hand-in' || homeworkStatusVariant === 'hand-in-red') && !dueDate) ? null : (
        <HomeworkStatus
          className="sf-subsection__status"
          variant={homeworkStatusVariant}
          points={earnedGrade}
          maxPoints={possibleGrade}
          dateUntil={dueDate}
        />
      )}
    </Link>
  );
};

export default Subsection;
