import React, { useContext, useEffect, useState } from 'react';
import {
  InputText, Button, Text, PasswordInput, useNotification,
} from '@skillfactory/frontend-theme';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConfig } from '@edx/frontend-platform';
import { AppContext } from '@edx/frontend-platform/react';
import { Redirect } from 'react-router';
import { postLoginSession, registerUser } from '../../data/api';
import { getRedirectUrl } from '../../utils/get-redirect-url';
import { useDecodedRedirectQueryParam } from '../../hooks/use-decoded-redirect-query-param';
import {
  emailValidator, fullNameValidator, passwordValidator, usernameValidator,
} from '../../utils/validators';
import AuthPageLayout from '../../auth-page-layout/AuthPageLayout';

const RegisterPage = () => {
  const history = useHistory();
  const [errors, setErrors] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const notification = useNotification();

  const formik = useFormik({
    initialValues: {
      fullName: '',
      username: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      fullName: fullNameValidator,
      username: usernameValidator,
      email: emailValidator,
      password: passwordValidator,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: ({
      fullName,
      username,
      email,
      password,
    }) => {
      setSubmitting(true);
      setErrors(null);
      registerUser({
        email,
        name: fullName,
        username,
        password,
      })
        .then(() => {
          // Do it in non-spa way because otherwise authenticated user is not initialized
          window.location.href = history.createHref({ pathname: '/register-success', search: `email=${encodeURIComponent(email)}` });
        })
        .catch(err => {
          if (err.customAttributes?.httpErrorStatus >= 500) {
            notification.show(
              'failure',
              'Возникла непредвиденная ошибка. Пожалуйста, обратитесь в поддержку.',
            );
          }
          setErrors(err.customAttributes?.httpErrorResponseData
            ? JSON.parse(err.customAttributes.httpErrorResponseData)
            : null);
        })
        .finally(() => setSubmitting(false));
    },
  });

  const { authenticatedUser } = useContext(AppContext);
  if (authenticatedUser) { return <Redirect to="/dashboard" />; }

  const getErrorMessageForField = (field) => {
    if (!errors || !errors[field] || !errors[field][0]) { return null; }
    return errors[field][0].user_message;
  };

  return (
    <AuthPageLayout
      title="Создайте свой профиль"
      bottomLeftElement={(
        <>
          <Text className="sf-auth-page-layout__footer-text" level={4}>Уже есть профиль?</Text>{' '}
          <Link to="/login" className="sf-auth-page-layout__link">Войдите</Link>
        </>
          )}
      isLoading={isSubmitting}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <InputText
          className="sf-auth-page-layout__input"
          label="Электронная почта"
          name="email"
          value={formik.values.email}
          errorMessage={formik.errors.email || getErrorMessageForField('email')}
          onChange={value => formik.setFieldValue('email', value)}
        />
        <InputText
          className="sf-auth-page-layout__input"
          label="Имя и фамилия"
          name="fullName"
          value={formik.values.fullName}
          errorMessage={formik.errors.fullName || getErrorMessageForField('name')}
          onChange={value => formik.setFieldValue('fullName', value)}
        />
        <InputText
          className="sf-auth-page-layout__input"
          label="Логин"
          name="username"
          value={formik.values.username}
          errorMessage={formik.errors.username || getErrorMessageForField('username')}
          onChange={value => formik.setFieldValue('username', value)}
        />
        <PasswordInput
          className="sf-auth-page-layout__input"
          label="Пароль"
          name="password"
          value={formik.values.password}
          errorMessage={formik.errors.password || getErrorMessageForField('password')}
          onChange={value => formik.setFieldValue('password', value)}
        />
        <span className="sf-auth-page-layout__note">
          Создавая профиль, соглашаюсь с{' '}
          <a className="sf-auth-page-layout__note-link" href="https://skillfactory.ru/position_of_user_personal_data" target="_blank">условиями Skillfactory</a> и{' '}
          <a className="sf-auth-page-layout__note-link" href="https://skillfactory.ru/dogovor-oferta-for-individuals" target="_blank">политикой конфиденциальности</a>
        </span>
        <Button
          variant="lime"
          type="submit"
          className="sf-auth-page-layout__submit-btn"
          disabled={isSubmitting}
        >
          Создать профиль
        </Button>
        {/* {errors && <Text className="sf-auth-page-layout__error-msg" level={7}>Возникла ошибка, попробуйте еще раз</Text>} */}
      </form>
    </AuthPageLayout>
  );
};

export default RegisterPage;
