import React, {
  useMemo, useState,
} from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useSelector } from 'react-redux';
import { CourseMenu, Text } from '@skillfactory/frontend-theme';
import SubsectionList from './subsection-list/SubsectionList';
import { Loader } from '@skillfactory/frontend-theme';

import { useModel } from '../../generic/model-store';

function Outline({ intl }) {
  const {
    courseId,
    courseStatus,
  } = useSelector(state => state.courseHome);

  const tabLoaded = useMemo(() => courseStatus === 'loaded', [courseStatus]);

  const outline = useModel('outline', courseId);

  const {
    isSelfPaced,
    org,
    title,
    username,
    userTimezone,
    originalUserIsStaff,
    celebrations,
    canViewLegacyCourseware,
  } = useModel('courseHomeMeta', courseId);

  const courseMenuItems = useMemo(() => tabLoaded ? Object.values(outline.courseBlocks.sections || {}).map(section => {
    const currentLesson = section.sequenceIds
        ?.filter(sequenceId => outline.courseBlocks.sequences[sequenceId]?.complete)
        ?.length;

    return {
      sectionName: section.title,
      state: 'current',
      subsectionIds: section.sequenceIds,
      totalLessons: section.sequenceIds.length,
      currentLesson: currentLesson,
    };
  }) : [], [outline, tabLoaded]);

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const subsectionListItems = useMemo(() => tabLoaded ? courseMenuItems[selectedSectionIndex]
    ?.subsectionIds
    .map(subsectionId => {
      const subsection = outline.courseBlocks.sequences[subsectionId];
      return {
        id: subsection.id,
        title: subsection.title,
        href: `/course/${courseId}/${subsection.id}`,
        status: null,
        dueDate: subsection.due && new Date(subsection.due),
        numberOfTasks: subsection.children?.length || 0,
        isCurrent: subsection.id === outline.resumeCourse.parentBlock,
      };
    }) : [] || [], [selectedSectionIndex, outline, tabLoaded]);

  const { tabs } = useModel('courseHomeMeta', courseId);
  const innerTabs = useMemo(() => {
    if (!tabs) { return []; }
    return tabs.filter(tab => tab.slug === 'progress' || tab.slug === 'outline');
  }, [tabs]);

  return (
    <div className="sf-outline-page__outline-menu">
      {tabLoaded ? (
      <>
      {courseMenuItems.length? <>
        <CourseMenu
            items={courseMenuItems}
            selectedIndex={selectedSectionIndex}
            onSelect={(index) => setSelectedSectionIndex(index)}
            className="sf-outline-page__course-menu"
        />
        <SubsectionList
            className="sf-outline-page__subsection-list"
            subsections={subsectionListItems}
        />
      </> : <Text level={3}>Здесь пока пусто.</Text>}

      <aside className="sf-outline-page__aside">
        {/* <ProjectsBanner className="sf-outline-page__projects-banner"/> */}
        {/* <a className="sf-outline-page__slack-link" href="/"> */}
        {/*    <ShapedIcon */}
        {/*        className="sf-outline-page__slack-link-icon" */}
        {/*        viewBox="0 0 28 28" */}
        {/*        icon={<SlackIcon />} */}
        {/*        size="bigger" */}
        {/*    /> */}
        {/*    <Text level={3}>Чат курса в Slack</Text> */}
        {/*    <ArrowIcon className="sf-outline-page__slack-link-arrow"/> */}
        {/* </a> */}
      </aside>
      </>
      ) : (
        <>
          <div />
          <Loader className='sf-outline-page__loader' />
          <div />
        </>
      )}
      
    </div>
  );
}

Outline.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Outline);
