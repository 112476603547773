import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text, Button, useAppStore, Warning,
} from '@skillfactory/frontend-theme';
import { ReactComponent as ArrowIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/arrow.svg';
import { getConfig, history } from '@edx/frontend-platform';
import { ReactComponent as FlagIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/flag.svg';
import { ReactComponent as LockIcon } from '../../assets/images/lock.svg';
import { useModel } from '../../generic/model-store';
import SequenceTab from './sequence-tab/SequenceTab';
import { checkBlockCompletion } from '../data';
import Unit from '../course/sequence/Unit';
import { initCoursewareMMP2P } from '../../experiments/mm-p2p';
import ExamWrapper from '../exam/ExamWrapper';
import SequenceTabViewNavigation from './sequence-tab-view-navigation/SequenceTabViewNavigation';

const getNumberOfTasksString = (number) => {
  const lastTwoDigits = number % 100;
  const lastDigit = lastTwoDigits % 10;
  if (!(lastTwoDigits >= 11 && lastTwoDigits <= 14)) {
    if (lastDigit === 1) { return `${number} задание`; }
    if (lastDigit >= 2 && lastDigit <= 4) { return `${number} задания`; }
  }
  return `${number} заданий`;
};

const SequenceTabView = ({
  courseId,
  sequenceId,
  courseStatus,
  unitId,
  section,
  nextSequenceHandler,
  previousSequenceHandler,
  unitNavigationHandler,
  className,
}) => {
  const sequence = useModel('sequences', sequenceId);
  const dispatch = useDispatch();
  const isGated = sequence.gatedContent?.gated ?? false;

  const unitIdsToUnits = useSelector(state => state.models.units);
  const units = useMemo(() => (unitIdsToUnits && sequence ? sequence.unitIds.map(unitId => {
    const unit = unitIdsToUnits[unitId];
    return {
      id: unit.id,
      title: unit.title,
      isCompleted: unit.complete,
    };
  }) : []), [unitIdsToUnits, sequence]);

  const handleUnitNavigationClick = (nextUnitId) => {
    dispatch(checkBlockCompletion(courseId, sequenceId, unitId));
    history.push(`/course/${courseId}/${sequenceId}/${nextUnitId}`);
  };
  const totalNumberOfUnits = useMemo(() => sequence.unitIds.length, [sequence]);
  const currentUnitNumber = useMemo(() => sequence.unitIds.indexOf(unitId) + 1, [unitId, sequence]);

  const unit = useModel('units', unitId);

  const [unitHasLoaded, setUnitHasLoaded] = useState(false);
  const handleUnitLoaded = () => {
    setUnitHasLoaded(true);
  };

  const MMP2P = initCoursewareMMP2P(courseId, sequenceId, unitId);

  const handleNavigate = (destinationUnitId) => {
    unitNavigationHandler(destinationUnitId);
  };

  const handleNext = () => {
    const nextIndex = sequence.unitIds.indexOf(unitId) + 1;
    if (nextIndex < sequence.unitIds.length) {
      const newUnitId = sequence.unitIds[nextIndex];
      handleNavigate(newUnitId);
    } else {
      nextSequenceHandler();
    }
  };

  const handlePrevious = () => {
    const previousIndex = sequence.unitIds.indexOf(unitId) - 1;
    if (previousIndex >= 0) {
      const newUnitId = sequence.unitIds[previousIndex];
      handleNavigate(newUnitId);
    } else {
      previousSequenceHandler();
    }
  };

  const { ref: appRef } = useAppStore();
  const content = useMemo(() => appRef?.current?.querySelector('.sf-app__content'), [appRef]);

  return (
    <ExamWrapper sequence={sequence} courseId={courseId}>
      <div className={classNames('sf-sequence-tab-view', { 'sf-sequence-tab-view--gated': isGated }, className)}>
        <div className="sf-sequence-tab-view__header">
          {sequence?.isTimeLocked && (
            <Warning
              className="sf-sequence-tab-view__warning"
              label="Этот пользователь не имеет доступа к этому контенту, так как дата начала его просмотра наступает в будущем"
            />
          )}
          <Text className="sf-sequence-tab-view__title" level={1}>
            {!isGated && (
            <>
              <span
                className="sf-sequence-tab-view__numbers"
              >{currentUnitNumber}/{totalNumberOfUnits}
              </span> &nbsp;&nbsp;
            </>
            )}
            {unit?.title}
          </Text>
          <span className="sf-sequence-tab-view__subtitle">
            {/* {unit?.children && <Text level={4} weight="regular">{getNumberOfTasksString(unit.children.length)}</Text>} */}
            {/* {unit?.due && (
              <span>
                <Text level={4} weight="regular">
                  Закрыть модуль до {unit.due.toLocaleDateString('ru-RU', {
                  day: 'numeric',
                  month: 'long',
                })}
                </Text>
                <FlagIcon className="sf-sequence-tab-view__subtitle-flag" />
              </span>
            )} */ }
          </span>

          {!isGated && (
          <SequenceTabViewNavigation
            units={units}
            handleUnitNavigationClick={handleUnitNavigationClick}
            currentUnitId={unitId}
          />
          )}
        </div>

        {isGated ? (
          <div className="sf-sequence-tab-view__gated-content">
            <div className="sf-sequence-tab-view__gated-content-icon">
              <LockIcon />
            </div>
            <Text className="sf-sequence-tab-view__title" level={2}>
              Модуль закрыт
            </Text>
            <Text className="sf-sequence-tab-view__title" level={3}>
              Чтобы начать этот модуль, завершите:
            </Text>
            <Text
              className="sf-sequence-tab-view__gated-content-link"
              level={2}
              as={(
                <a

                  href={`${getConfig().LMS_BASE_URL}${sequence.gatedContent?.prereqUrl}`}
                  target="_blank"
                />
)}
            >
              «{sequence.gatedContent?.prereqSectionName}»
            </Text>
          </div>
        ) : (
          <>
            <Unit
              courseId={courseId}
              format={sequence.format}
              id={unitId}
              sequenceId={sequenceId}
              onLoaded={handleUnitLoaded}
              notificationTrayVisible={false}
              section={section}
              /** [MM-P2P] Experiment */
              mmp2p={MMP2P}
            />
            <div className="sf-sequence-tab-view__nav-buttons">
              <Button
                icon={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                iconPosition="left"
                onClick={() => {
                  handlePrevious();
                  content?.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                Предыдущий урок
              </Button>
              <Button
                icon={<ArrowIcon />}
                onClick={() => {
                  handleNext();
                  content?.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                Следующий урок
              </Button>
            </div>
          </>
        )}

      </div>
    </ExamWrapper>
  );
};

export default SequenceTabView;
