import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { reducer as courseHomeReducer } from './course-home/data';
import { reducer as coursewareReducer } from './courseware/data/slice';
import { reducer as recommendationsReducer } from './courseware/course/course-exit/data/slice';
import { reducer as modelsReducer } from './generic/model-store';
import { reducer as dashboardReducer } from './dashboard/data/slice';
import { reducer as deadlinesReducer } from './deadlines/data/slice';
import { reducer as authReducer } from './auth/data/slice';

enableMapSet();

export default function initializeStore() {
  enableMapSet();

  return configureStore({
    reducer: {
      models: modelsReducer,
      courseware: coursewareReducer,
      courseHome: courseHomeReducer,
      recommendations: recommendationsReducer,
      dashboard: dashboardReducer,
      deadlines: deadlinesReducer,
      auth: authReducer,
    },
  });
}
