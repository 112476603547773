import React, {
  useEffect,
  useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import SequenceTab from '../sequence-tab/SequenceTab';
import './_sequence-tab-view-navigation.scss';
import { RoundIconButton, useWindowSize } from '@skillfactory/frontend-theme';
import { ReactComponent as ArrowIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/arrow.svg';

const SequenceTabViewNavigation = ({ units = [], currentUnitId, handleUnitNavigationClick }) => {
  const step = 150;
  const [horizontalOffset, setHorizontalOffset] = useState(0);
  const [hasPrevItems, setHasPrevItems] = useState(false);
  const [hasNextItems, setHasNextItems] = useState(false);
  const firstTab = useRef(null);
  const lastTab = useRef(null);
  const outerContainer = useRef(null);
  const tabContainer = useRef(null);

  const getTabRef = (index) => {
    if (index === 0) { return firstTab; }
    if (index === units.length - 1) { return lastTab; }
    return undefined;
  };

  const [maxOffset, setMaxOffset] = useState(0);

  const { width } = useWindowSize();
  useLayoutEffect(() => {
    setHorizontalOffset(0);
    setMaxOffset(tabContainer.current.scrollWidth - tabContainer.current.clientWidth + 1);
  }, [width]);

  // const maxOffset = tabContainer.current ? scrollWidth - tabContainer.current.clientWidth : undefined;

  useEffect(() => {
    if (units.length < 2) { return; }
    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.target === firstTab.current) {
          setHasPrevItems(!entry.isIntersecting);
          return;
        }
        setHasNextItems(!entry.isIntersecting);
      });
    }, { root: outerContainer.current, threshold: 1 });

    observer.observe(firstTab.current);
    observer.observe(lastTab.current);

    return () => {
      observer.disconnect();
      // resizeObserver.disconnect();
    };
  }, [units]);

  const onPrevButtonClick = () => {
    setHorizontalOffset(prevState => {
      const newOffset = prevState - step;
      if (newOffset > 0) { return newOffset; }
      return 0;
    });
  };

  const onNextButtonClick = () => {
    setHorizontalOffset(prevState => {
      const newOffset = prevState + step;
      if (maxOffset && newOffset < maxOffset) { return newOffset; }
      return maxOffset;
    });
  };

  return (
    <div className="sequence-tab-view-navigation">
      {hasPrevItems && (
      <div className="sequence-tab-view-navigation__button-with-fade sequence-tab-view-navigation__button-with-fade--first">
        <RoundIconButton
          className="sequence-tab-view-navigation__arrow-button"
          onClick={onPrevButtonClick}
          icon={<ArrowIcon />}
          size="small"
        />
      </div>
      )}
      <div ref={outerContainer} style={{ overflow: 'hidden', flex: '0 0 100%' }}>
        <div ref={tabContainer} className="sequence-tab-view-navigation__tabs-container" style={{ transform: `translateX(${-horizontalOffset}px)` }}>
          {units.map((unit, index) => (
            <SequenceTab
              ref={getTabRef(index)}
              key={unit.id}
              className="sequence-tab-view-navigation__tab"
              isCurrentLesson={unit.id === currentUnitId}
              isCompleted={unit.isCompleted}
              unitTitle={unit.title}
              onClick={() => handleUnitNavigationClick(unit.id)}
            />
          ))}
        </div>
      </div>
      {hasNextItems && (
      <div className="sequence-tab-view-navigation__button-with-fade">
        <RoundIconButton
          className="sequence-tab-view-navigation__arrow-button"
          onClick={onNextButtonClick}
          icon={<ArrowIcon />}
          size="small"
        />
      </div>
      )}
    </div>

  );
};

SequenceTabViewNavigation.propTypes = PropTypes.shape({
  units: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  })),
  handleUnitNavigationClick: PropTypes.func.isRequired,
  currentUnitId: PropTypes.number.isRequired,
});

export default SequenceTabViewNavigation;
