import React, { useContext, useState } from 'react';
import { InputText, Button, Text } from '@skillfactory/frontend-theme';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from '@edx/frontend-platform/react';
import { Redirect } from 'react-router';
import { resetPassword } from '../../data/api';
import { emailValidator } from '../../utils/validators';
import AuthPageLayout from '../../auth-page-layout/AuthPageLayout';

const ResetPasswordPage = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: emailValidator,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: ({
      email,
    }) => {
      setSubmitting(true);
      resetPassword(email)
        .then(() => {
          history.push('/password-reset-success', {
            email,
          });
        })
        .catch(res => {
          const msg = res.data?.value;
          msg
            ? setErrorMsg(msg)
            : setErrorMsg('Возникла ошибка, попробуйте еще раз');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { authenticatedUser } = useContext(AppContext);
  if (authenticatedUser) { return <Redirect to="/dashboard" />; }

  return (
    <AuthPageLayout
      title="Восстановление пароля"
      subtitle="Укажите адрес электронной почты, и мы вышлем вам инструкции по восстановлению пароля"
      bottomLeftElement={<Link to="/login" className="sf-auth-page-layout__link">Вспомнил пароль</Link>}
      isLoading={isSubmitting}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <InputText
          className="sf-auth-page-layout__input"
          label="Электронная почта"
          name="email"
          value={formik.values.email}
          errorMessage={formik.errors.email}
          onChange={value => formik.setFieldValue('email', value)}
        />
        <Button
          shape="sloped"
          variant="lime"
          type="submit"
          className="sf-auth-page-layout__submit-btn"
          disabled={isSubmitting}
        >
          Отправить
        </Button>
        {errorMsg && <Text className="sf-auth-page-layout__error-msg" level={7}>{errorMsg}</Text>}
      </form>
    </AuthPageLayout>
  );
};

export default ResetPasswordPage;
